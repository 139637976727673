.pagination {
    margin-bottom: $margin-bottom;
    ul {
        margin: 0;
        li {
            display: inline-block;
            margin: 0;
            a {
                @extend .button;
                @include no-triangle-button;
                margin-bottom: $margin-bottom/4;
                text-align: center;
                padding: rem-calc(5 15);
                &:before,
                &:after {
                    color: #fff;
                }
            }
            &.current {
                a {
                    background-color: $color-octonary;
                    color: $color-text;
                }
            }
            &.first,
            &.previous,
            &.next,
            &.last {
                @include breakpoint($fix-xsmall down) {
                    display: block;
                }
                a {
                    &:hover,
                    &:active,
                    &:focus {
                        &:before,
                        &:after {
                            color: #fff;
                        }
                    }
                    &:before {
                        margin-right: rem-calc(5);
                    }
                    &:after {
                        margin-left: rem-calc(5);
                    }
                    @include breakpoint($fix-xsmall down) {
                        width: 100%;
                    }
                }
                &.disabled {
                    cursor: default;
                    padding: 0;
                    a {
                        cursor: default;
                        background-color: $color-shade;
                        color: $color-text;
                        &:before,
                        &:after {
                            color: $color-text;
                        }
                    }
                }
            }
            &.first a {
                &:before {
                    content: '≪';
                }
            }
            &.previous a {
                &:before {
                    content: '<';
                }
            }
            &.next a {
                &:after {
                    content: '>';
                }
            }
            &.last a {
                &:after {
                    content: '≫';
                }
            }
            &.next a,
            &.last a {
                &:after {
                    display: inline-block;
                    transform: none;
                    position: static;
                    background: none;
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}