.simple-block {
    .simple-block__headline {
        @include fontstyle(h3);
    }

    &.simple-block--shaded,
    &.simple-block--bright {
        padding: rem-calc(30);
    }

    &.simple-block--shaded {
        background-color: $color-shade-lightest;
    }
    &.simple-block--bright {
        background-color: $color-quaternary;
    }

    &.simple-block--stroke {
        @extend .simple-block--shaded;
        @include top-stroke;
    }
}