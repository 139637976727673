.sidebar-layout {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .sidebar-layout__main {
        flex: 0 1 69%;
    }

    .sidebar-layout__sidebar {
        flex: 0 1 24%;
    }

    .sidebar-layout__main,
    .sidebar-layout__sidebar {
        @include breakpoint(medium down) {
            flex: 0 1 100%;
        }
    }

    &.sidebar-layout--mobile-swap {
        @include breakpoint(medium down) {
            .sidebar-layout__main {
                order: 2;
            }
            .sidebar-layout__sidebar {
                order: 1;
            }
        }
    }
}