.fact-collection {
    @include rr-slab;

    .fact-collection__headline,
    .fact-collection__facts {
        @include rr-slab-wrapper;
    }

    .fact-collection__headline {
        // something
    }

    .fact-collection__facts {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .fact {
            flex: 0 1 $collection-4up;
            @include breakpoint(medium down) {
                flex: 0 1 $collection-2up;
            }
            @include breakpoint(small down) {
                flex: 0 1 100%;
                margin-bottom: $margin-bottom/1.5;
            }
        }
    }
}