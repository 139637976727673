.link-collection {
	margin-bottom: $margin-bottom;

	ul {
		@include no-bullets;
		ul {
			margin-left: rem-calc(50);
		}
	}

	.link-collection__headline {
		font-family: $font-tertiary;
	}

	&.link-collection--two-column {
		ul {
			column-count: 2;
			column-gap: $margin-bottom;
			@include breakpoint(medium down) {
				column-gap: rem-calc(30);
			}
			@include breakpoint($fix-small down) {
				column-count: 1;
			}
			li {
				break-inside: avoid;
				page-break-inside: avoid;
			}
			ul {
				column-count: 1;
			}
		}
	}

	&.link-collection--fancy {
		a {
			@extend .link--fancy;
		}
	}
}