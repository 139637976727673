// generate the image url by prefixing the layout image path
@function image-url($url) {
    @return url("#{$image-url-path}#{$url}");
  }
  
  // style input placeholders
  @mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder           {@content}
    &:-ms-input-placeholder      {@content}
  }