$color-link-on-dark: #fff;

@mixin ondark {
	color: $color-link-on-dark;

	abbr {
		color: $color-link-on-dark;
	}

	a {
		&:link,
		&:visited {
			color: $color-link-on-dark;
		}
		&:hover,
		&:active,
		&:focus {
			// color: $color-brand-alt;
		}
		&.link--fancy {
			&:before {
				border-color: #fff;
				background-image: image-url('fancy-link-angle-white.svg');
			}
			&:hover,
			&:active,
			&:focus {
				color: $color-quaternary;
				&:before {
					border-color: $color-quaternary;
				}
			}
			&.link--fancy-filled {
				&:before {
					background-color: $color-tertiary;
				}
				&:hover,
				&:active,
				&:focus {
					&:before {
						background-color: $color-octonary;
					}
				}
			}
		}
	}

	blockquote {
		border-color: #fff;
	}

	hr {
		border-color: rgba(#fff, 0.2);
	}

	table {
		color: $color-text;
		caption {
			color: #fff;
		}
	}

	button,
	.button {
		background-color: $color-tertiary;
		&:after {
			background-color: $color-octonary;
		}
		&:hover,
		&:active {
			color: $color-text;
		}
	}

	.social-media {
		a {
			color: $color-octonary;
			&:hover,
			&:active,
			&:focus {
				// color: $color-quaternary;
				color: #fff;
			}
		}
	}
	.breadcrumbs {
		color: $color-quaternary;
	}
}

.ondark {
	@include ondark;
}


@mixin onbright {
	a {
		&.link--fancy {
			&:before {
				border-color: #fff;
			}
			&:hover,
			&:active,
			&:focus {
				&:before {
					border-color: $color-tertiary;
				}
			}
		}
	}
	hr {
		border-color: rgba(#fff, 0.5);
		&.hr--dots {
			@include border-dotted($sides: 'bottom', $color: #fff);
		}
	}
	button,
	.button {
		background-color: $color-accent-octonary;
		&:after {
			background-color: $color-accent-nonary;
		}
		&:hover,
		&:active {
			// color: $color-text;
		}
	}
	ul.dot-list {
		li {
			&::after {
				background-color: #fff;
			}
		}
	}
	table {
		thead {
			tr {
				th {
					background-color: $color-accent-octonary;
				}
			}
		}
	}
	.social-media {
		a {
			color: $color-primary;
			&:hover,
			&:active,
			&:focus {
				color: $color-secondary;
			}
		}
	}
	.breadcrumbs {
		ul {
			li {
				&::after {
					background-color: #fff;
				}
			}
		}
	}
	.list-header {
		@include border-dotted($sides: 'bottom', $color: #fff);
	}
}

.onbright {
	@include onbright;
}



// remove these later
// for pattern scaffolding only
.sg-pattern[id$='on-dark'] {
	.sg-pattern-example {
	background: $color-primary;
	@include ondark;
	}
}
.sg-pattern[id$='on-bright'] {
	.sg-pattern-example {
	background: $color-quaternary;
	@include onbright;
	}
}
.sg-pattern[id$='on-dark'],
.sg-pattern[id$='on-bright'] {
	.sg-pattern-example {
		padding: rem-calc(20);
	}
}