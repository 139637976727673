// font embed from google fonts
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Raleway:300,300i,400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed|Noto+Sans:400,700|Spectral');

// Setup font families
$font-primary : 'Noto Sans', sans-serif;
$font-secondary: 'Spectral', serif;
$font-tertiary : 'Barlow Condensed', sans-serif;
$font-quaternary: $font-primary;

// Set font variables, if any
$font-accent: $font-tertiary;

// Font sizing
$base-font-size: 16px;


// use these classes for testing/demoing fonts only.
// don't add them to components
.font-primary {
    font-family: $font-primary;
}
.font-secondary {
    font-family: $font-secondary;
}
.font-tertiary {
    font-family: $font-tertiary;
}
.font-quaternary {
    font-family: $font-quaternary;
}