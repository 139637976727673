.page-header {
    @include rr-slab;
    background: $color-primary;
    @include ondark;

    .page-header__wrapper {
        @include rr-slab-wrapper;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .page-header__body {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
        }
        .page-header__media {
            flex: 1 0 50%;
            margin-left: rem-calc(40);
        }
    }


    &.page-header--fill-media {
        position: relative;
        overflow: hidden;
        .page-header__wrapper {
            .page-header__body {
                min-height: rem-calc(400);
                flex: 0 1 50%;
                padding-right: rem-calc(40);
            }
            .page-header__media {
                @include pseudo-cover($beneath: true, $pin: 'center' 'center');
                max-width: 50%;
                left: auto;
                right: 0;
                overflow: hidden;
            }
        }
    }
}