figure {
	img {
		width: 100%;
		height: auto;
	}

	figcaption {
		// margin-top: 1em;
	}
}
