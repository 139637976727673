.media {
    .figure-wrapper {
        a {
            display: inline-block;
            @include link-strokeless;
            background-color: $color-link;
            img {
                transition: opacity $transition-time $transition-easing;
            }
            &:hover,
            &:active,
            &:focus {
                img {
                    opacity: 0.75;
                    // mix-blend-mode: multiply;
                }
            }
        }
    }
    &.media--video-indicator {
        .figure-wrapper {
            a {
                position: relative;
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: rem-calc(50);
                    height: rem-calc(50);
                    background: image-url('play.svg') no-repeat center center;
                }
            }
        }
    }
}