@charset "UTF-8";
/* Colors
/* remember to turn off any unused swatches in atoms>global>colors.twig
/* _______________________________________________________________ */
@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed|Noto+Sans:400,700|Spectral");
.font-primary {
  font-family: "Noto Sans", sans-serif; }

.font-secondary {
  font-family: "Spectral", serif; }

.font-tertiary {
  font-family: "Barlow Condensed", sans-serif; }

.font-quaternary {
  font-family: "Noto Sans", sans-serif; }

.ondark {
  color: #fff; }
  .ondark abbr {
    color: #fff; }
  .ondark a:link, .ondark a:visited {
    color: #fff; }
  .ondark a.link--fancy:before, .ondark .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .ondark a:before {
    border-color: #fff;
    background-image: url("../images/fancy-link-angle-white.svg"); }
  .ondark a.link--fancy:hover, .ondark .link-collection.link-collection--fancy a:hover, .link-collection.link-collection--fancy .ondark a:hover, .ondark a.link--fancy:active, .ondark .link-collection.link-collection--fancy a:active, .link-collection.link-collection--fancy .ondark a:active, .ondark a.link--fancy:focus, .ondark .link-collection.link-collection--fancy a:focus, .link-collection.link-collection--fancy .ondark a:focus {
    color: #F2A900; }
    .ondark a.link--fancy:hover:before, .ondark .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .ondark a:hover:before, .ondark a.link--fancy:active:before, .ondark .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .ondark a:active:before, .ondark a.link--fancy:focus:before, .ondark .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .ondark a:focus:before {
      border-color: #F2A900; }
  .ondark a.link--fancy.link--fancy-filled:before, .ondark .link-collection.link-collection--fancy a.link--fancy-filled:before, .link-collection.link-collection--fancy .ondark a.link--fancy-filled:before {
    background-color: #007DBA; }
  .ondark a.link--fancy.link--fancy-filled:hover:before, .ondark .link-collection.link-collection--fancy a.link--fancy-filled:hover:before, .link-collection.link-collection--fancy .ondark a.link--fancy-filled:hover:before, .ondark a.link--fancy.link--fancy-filled:active:before, .ondark .link-collection.link-collection--fancy a.link--fancy-filled:active:before, .link-collection.link-collection--fancy .ondark a.link--fancy-filled:active:before, .ondark a.link--fancy.link--fancy-filled:focus:before, .ondark .link-collection.link-collection--fancy a.link--fancy-filled:focus:before, .link-collection.link-collection--fancy .ondark a.link--fancy-filled:focus:before {
    background-color: #41B6E6; }
  .ondark blockquote {
    border-color: #fff; }
  .ondark hr {
    border-color: rgba(255, 255, 255, 0.2); }
  .ondark table {
    color: #101820; }
    .ondark table caption {
      color: #fff; }
  .ondark button,
  .ondark .button,
  .ondark .pagination ul li a,
  .pagination ul li .ondark a {
    background-color: #007DBA; }
    .ondark button:after,
    .ondark .button:after,
    .ondark .pagination ul li a:after,
    .pagination ul li .ondark a:after {
      background-color: #41B6E6; }
    .ondark button:hover, .ondark button:active,
    .ondark .button:hover,
    .ondark .pagination ul li a:hover,
    .pagination ul li .ondark a:hover,
    .ondark .button:active,
    .ondark .pagination ul li a:active,
    .pagination ul li .ondark a:active {
      color: #101820; }
  .ondark .social-media a {
    color: #41B6E6; }
    .ondark .social-media a:hover, .ondark .social-media a:active, .ondark .social-media a:focus {
      color: #fff; }
  .ondark .breadcrumbs {
    color: #F2A900; }

.onbright a.link--fancy:before, .onbright .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .onbright a:before {
  border-color: #fff; }

.onbright a.link--fancy:hover:before, .onbright .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .onbright a:hover:before, .onbright a.link--fancy:active:before, .onbright .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .onbright a:active:before, .onbright a.link--fancy:focus:before, .onbright .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .onbright a:focus:before {
  border-color: #007DBA; }

.onbright hr {
  border-color: rgba(255, 255, 255, 0.5); }
  .onbright hr.hr--dots {
    border-width: 0;
    border-style: solid;
    border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
    border-bottom-width: 18px; }

.onbright button,
.onbright .button,
.onbright .pagination ul li a,
.pagination ul li .onbright a {
  background-color: #C35413; }
  .onbright button:after,
  .onbright .button:after,
  .onbright .pagination ul li a:after,
  .pagination ul li .onbright a:after {
    background-color: #D3710C; }

.onbright ul.dot-list li::after, .onbright ul.news-meta li::after {
  background-color: #fff; }

.onbright table thead tr th {
  background-color: #C35413; }

.onbright .social-media a {
  color: #012169; }
  .onbright .social-media a:hover, .onbright .social-media a:active, .onbright .social-media a:focus {
    color: #0033A0; }

.onbright .breadcrumbs ul li::after {
  background-color: #fff; }

.onbright .list-header {
  border-width: 0;
  border-style: solid;
  border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
  border-bottom-width: 18px; }

.sg-pattern[id$='on-dark'] .sg-pattern-example {
  background: #012169;
  color: #fff; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example abbr {
    color: #fff; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example a:link, .sg-pattern[id$='on-dark'] .sg-pattern-example a:visited {
    color: #fff; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a:before {
    border-color: #fff;
    background-image: url("../images/fancy-link-angle-white.svg"); }
  .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy:hover, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a:hover, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a:hover, .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy:active, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a:active, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a:active, .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy:focus, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a:focus, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a:focus {
    color: #F2A900; }
    .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy:hover:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a:hover:before, .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy:active:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a:active:before, .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy:focus:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a:focus:before {
      border-color: #F2A900; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy.link--fancy-filled:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a.link--fancy-filled:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy-filled:before {
    background-color: #007DBA; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy.link--fancy-filled:hover:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a.link--fancy-filled:hover:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy-filled:hover:before, .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy.link--fancy-filled:active:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a.link--fancy-filled:active:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy-filled:active:before, .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy.link--fancy-filled:focus:before, .sg-pattern[id$='on-dark'] .sg-pattern-example .link-collection.link-collection--fancy a.link--fancy-filled:focus:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-dark'] .sg-pattern-example a.link--fancy-filled:focus:before {
    background-color: #41B6E6; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example blockquote {
    border-color: #fff; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example hr {
    border-color: rgba(255, 255, 255, 0.2); }
  .sg-pattern[id$='on-dark'] .sg-pattern-example table {
    color: #101820; }
    .sg-pattern[id$='on-dark'] .sg-pattern-example table caption {
      color: #fff; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example button,
  .sg-pattern[id$='on-dark'] .sg-pattern-example .button,
  .sg-pattern[id$='on-dark'] .sg-pattern-example .pagination ul li a,
  .pagination ul li .sg-pattern[id$='on-dark'] .sg-pattern-example a {
    background-color: #007DBA; }
    .sg-pattern[id$='on-dark'] .sg-pattern-example button:after,
    .sg-pattern[id$='on-dark'] .sg-pattern-example .button:after,
    .sg-pattern[id$='on-dark'] .sg-pattern-example .pagination ul li a:after,
    .pagination ul li .sg-pattern[id$='on-dark'] .sg-pattern-example a:after {
      background-color: #41B6E6; }
    .sg-pattern[id$='on-dark'] .sg-pattern-example button:hover, .sg-pattern[id$='on-dark'] .sg-pattern-example button:active,
    .sg-pattern[id$='on-dark'] .sg-pattern-example .button:hover,
    .sg-pattern[id$='on-dark'] .sg-pattern-example .pagination ul li a:hover,
    .pagination ul li .sg-pattern[id$='on-dark'] .sg-pattern-example a:hover,
    .sg-pattern[id$='on-dark'] .sg-pattern-example .button:active,
    .sg-pattern[id$='on-dark'] .sg-pattern-example .pagination ul li a:active,
    .pagination ul li .sg-pattern[id$='on-dark'] .sg-pattern-example a:active {
      color: #101820; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example .social-media a {
    color: #41B6E6; }
    .sg-pattern[id$='on-dark'] .sg-pattern-example .social-media a:hover, .sg-pattern[id$='on-dark'] .sg-pattern-example .social-media a:active, .sg-pattern[id$='on-dark'] .sg-pattern-example .social-media a:focus {
      color: #fff; }
  .sg-pattern[id$='on-dark'] .sg-pattern-example .breadcrumbs {
    color: #F2A900; }

.sg-pattern[id$='on-bright'] .sg-pattern-example {
  background: #F2A900; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example a.link--fancy:before, .sg-pattern[id$='on-bright'] .sg-pattern-example .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-bright'] .sg-pattern-example a:before {
    border-color: #fff; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example a.link--fancy:hover:before, .sg-pattern[id$='on-bright'] .sg-pattern-example .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-bright'] .sg-pattern-example a:hover:before, .sg-pattern[id$='on-bright'] .sg-pattern-example a.link--fancy:active:before, .sg-pattern[id$='on-bright'] .sg-pattern-example .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-bright'] .sg-pattern-example a:active:before, .sg-pattern[id$='on-bright'] .sg-pattern-example a.link--fancy:focus:before, .sg-pattern[id$='on-bright'] .sg-pattern-example .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .sg-pattern[id$='on-bright'] .sg-pattern-example a:focus:before {
    border-color: #007DBA; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example hr {
    border-color: rgba(255, 255, 255, 0.5); }
    .sg-pattern[id$='on-bright'] .sg-pattern-example hr.hr--dots {
      border-width: 0;
      border-style: solid;
      border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
      border-bottom-width: 18px; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example button,
  .sg-pattern[id$='on-bright'] .sg-pattern-example .button,
  .sg-pattern[id$='on-bright'] .sg-pattern-example .pagination ul li a,
  .pagination ul li .sg-pattern[id$='on-bright'] .sg-pattern-example a {
    background-color: #C35413; }
    .sg-pattern[id$='on-bright'] .sg-pattern-example button:after,
    .sg-pattern[id$='on-bright'] .sg-pattern-example .button:after,
    .sg-pattern[id$='on-bright'] .sg-pattern-example .pagination ul li a:after,
    .pagination ul li .sg-pattern[id$='on-bright'] .sg-pattern-example a:after {
      background-color: #D3710C; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example ul.dot-list li::after, .sg-pattern[id$='on-bright'] .sg-pattern-example ul.news-meta li::after {
    background-color: #fff; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example table thead tr th {
    background-color: #C35413; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example .social-media a {
    color: #012169; }
    .sg-pattern[id$='on-bright'] .sg-pattern-example .social-media a:hover, .sg-pattern[id$='on-bright'] .sg-pattern-example .social-media a:active, .sg-pattern[id$='on-bright'] .sg-pattern-example .social-media a:focus {
      color: #0033A0; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example .breadcrumbs ul li::after {
    background-color: #fff; }
  .sg-pattern[id$='on-bright'] .sg-pattern-example .list-header {
    border-width: 0;
    border-style: solid;
    border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
    border-bottom-width: 18px; }

.sg-pattern[id$='on-dark'] .sg-pattern-example,
.sg-pattern[id$='on-bright'] .sg-pattern-example {
  padding: 1.25rem; }

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #B1B3B3; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #012169;
  text-decoration: underline;
  cursor: pointer; }
  a:hover, a:focus {
    color: #011c5a; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #B1B3B3;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #000; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: inherit; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: inherit; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #B1B3B3;
  background-color: #D9D9D6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #D9D9D6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #7d8181; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #7d8181;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #B1B3B3;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #7d8181;
    background-color: #fff;
    box-shadow: 0 0 5px #B1B3B3;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #B1B3B3; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #D9D9D6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: inherit; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #B1B3B3;
  background: #D9D9D6;
  color: #000;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #B1B3B3; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #B1B3B3;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28125, 129, 129%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #7d8181;
    background-color: #fff;
    box-shadow: 0 0 5px #B1B3B3;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #D9D9D6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #B58500;
  background-color: #f8f3e6; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #B58500; }

.is-invalid-label {
  color: #B58500; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #B58500; }
  .form-error.is-visible {
    display: block; }

.close-button {
  position: absolute;
  color: #7d8181;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #000; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #B1B3B3;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #012169 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #012169; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #012169 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #012169; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #012169 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #012169 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #012169; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #012169 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #012169 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #012169; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #B1B3B3;
  background: #fff; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #012169 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #012169; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: ratio-to-percentage(4 by 3);
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: ratio-to-percentage(16 by 9); }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #B1B3B3;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #B1B3B3;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fff;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #012169; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

.tabs {
  margin: 0;
  border: 1px solid #D9D9D6;
  background: #0033A0;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #012169; }
  .tabs.primary > li > a {
    color: #fff; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #01277d; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 1rem;
    line-height: 1;
    color: #012169; }
    .tabs-title > a:hover {
      background: #007DBA;
      color: #011c5a; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #007DBA;
      color: #012169; }

.tabs-content {
  border: 1px solid #D9D9D6;
  border-top: 0;
  background: #fff;
  color: #000;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #D9D9D6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.bg-primary {
  background-color: #012169; }
  .bg-primary::before {
    content: "#012169";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-secondary {
  background-color: #0033A0; }
  .bg-secondary::before {
    content: "#0033A0";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-tertiary {
  background-color: #007DBA; }
  .bg-tertiary::before {
    content: "#007DBA";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-quaternary {
  background-color: #F2A900; }
  .bg-quaternary::before {
    content: "#F2A900";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-quinary {
  background-color: #B58500; }
  .bg-quinary::before {
    content: "#B58500";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-senary {
  background-color: #8D702A; }
  .bg-senary::before {
    content: "#8D702A";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-septenary {
  background-color: #00AEEF; }
  .bg-septenary::before {
    content: "#00AEEF";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-octonary {
  background-color: #41B6E6; }
  .bg-octonary::before {
    content: "#41B6E6";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-nonary {
  background-color: #000; }
  .bg-nonary::before {
    content: "#000";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-denary {
  background-color: #000; }
  .bg-denary::before {
    content: "#000";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-primary {
  background-color: #9EE1E7; }
  .bg-accent-primary::before {
    content: "#9EE1E7";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-secondary {
  background-color: #55A0A6; }
  .bg-accent-secondary::before {
    content: "#55A0A6";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-tertiary {
  background-color: #487F84; }
  .bg-accent-tertiary::before {
    content: "#487F84";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-quaternary {
  background-color: #99E69D; }
  .bg-accent-quaternary::before {
    content: "#99E69D";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-quinary {
  background-color: #348338; }
  .bg-accent-quinary::before {
    content: "#348338";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-senary {
  background-color: #006C5B; }
  .bg-accent-senary::before {
    content: "#006C5B";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-septenary {
  background-color: #006C5B; }
  .bg-accent-septenary::before {
    content: "#006C5B";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-octonary {
  background-color: #C35413; }
  .bg-accent-octonary::before {
    content: "#C35413";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-nonary {
  background-color: #D3710C; }
  .bg-accent-nonary::before {
    content: "#D3710C";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-accent-denary {
  background-color: #DA291C; }
  .bg-accent-denary::before {
    content: "#DA291C";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-eleven {
  background-color: #C6007E; }
  .bg-accent-eleven::before {
    content: "#C6007E";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-twelve {
  background-color: #6558B1; }
  .bg-accent-twelve::before {
    content: "#6558B1";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-accent-thirteen {
  background-color: #6D2077; }
  .bg-accent-thirteen::before {
    content: "#6D2077";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.bg-shade-lightest {
  background-color: #F3F3F2; }
  .bg-shade-lightest::before {
    content: "#F3F3F2";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-lighter {
  background-color: #D9D9D6; }
  .bg-shade-lighter::before {
    content: "#D9D9D6";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-light {
  background-color: #D0D0CE; }
  .bg-shade-light::before {
    content: "#D0D0CE";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade {
  background-color: #B1B3B3; }
  .bg-shade::before {
    content: "#B1B3B3";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-dark {
  background-color: #979a9a; }
  .bg-shade-dark::before {
    content: "#979a9a";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-darker {
  background-color: #7d8181; }
  .bg-shade-darker::before {
    content: "#7d8181";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5); }

.bg-shade-darkest {
  background-color: #101820; }
  .bg-shade-darkest::before {
    content: "#101820";
    display: inline-block;
    padding: .5em;
    border-radius: 0 0 4px 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5); }

.font-primary {
  font-family: "Noto Sans", sans-serif; }

.font-secondary {
  font-family: "Spectral", serif; }

.font-tertiary {
  font-family: "Barlow Condensed", sans-serif; }

.font-quaternary {
  font-family: "Noto Sans", sans-serif; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.align-center {
  text-align: center; }

.align-justify {
  text-align: justify; }

.align-justified-and-ancient {
  text-align: justify;
  font-family: fantasy; }

cite {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-style: normal; }
  cite:before {
    content: '- '; }

p {
  font: normal normal 16px "Noto Sans", sans-serif;
  text-transform: none;
  line-height: 1.625rem;
  margin-bottom: 1.875rem; }

hr {
  border-color: rgba(0, 0, 0, 0.3);
  border-bottom-width: 0.125rem;
  margin-top: 0;
  margin-bottom: 2.5rem;
  max-width: none; }
  hr.hr--dots {
    border-width: 0;
    border-style: solid;
    border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#F2A900'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
    border-bottom-width: 18px; }

a {
  text-decoration: none;
  color: #0033A0;
  background-image: linear-gradient(120deg, #41B6E6 0%, #00AEEF 100%);
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: 0 98%;
  transition: background-size 0.25s ease-in; }
  a:link, a:visited {
    color: #0033A0; }
  a:hover, a:active, a:focus {
    color: #0033A0; }
  a:focus {
    outline-color: #F2A900; }
  a:hover {
    background-size: 100% 98%; }
  a.link--tag, ul.news-meta li a {
    font-weight: bold; }
  a.link--fancy, .link-collection.link-collection--fancy a {
    background-image: none;
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
    display: inline-flex;
    transition: color 0.2s ease-out;
    padding-top: 0.4375rem; }
    a.link--fancy:before, .link-collection.link-collection--fancy a:before {
      display: inline-block;
      content: '';
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border: #D9D9D6 2px solid;
      flex: 0 0 auto;
      margin-right: 1.25rem;
      margin-top: -0.4375rem;
      transition: border-color 0.2s ease-out;
      background: url("../images/fancy-link-angle.svg") no-repeat center center;
      background-size: 7px 12px; }
    a.link--fancy:hover, .link-collection.link-collection--fancy a:hover, a.link--fancy:active, .link-collection.link-collection--fancy a:active, a.link--fancy:focus, .link-collection.link-collection--fancy a:focus {
      color: #007DBA; }
      a.link--fancy:hover:before, .link-collection.link-collection--fancy a:hover:before, a.link--fancy:active:before, .link-collection.link-collection--fancy a:active:before, a.link--fancy:focus:before, .link-collection.link-collection--fancy a:focus:before {
        border-color: #007DBA; }
    a.link--fancy.link--fancy-filled:before, .link-collection.link-collection--fancy a.link--fancy-filled:before {
      border: transparent;
      background-color: #0033A0;
      transition: background-color 0.2s ease-out;
      background-image: url("../images/fancy-link-angle-white.svg"); }
    a.link--fancy.link--fancy-filled:hover:before, .link-collection.link-collection--fancy a.link--fancy-filled:hover:before, a.link--fancy.link--fancy-filled:active:before, .link-collection.link-collection--fancy a.link--fancy-filled:active:before, a.link--fancy.link--fancy-filled:focus:before, .link-collection.link-collection--fancy a.link--fancy-filled:focus:before {
      background-color: #007DBA; }

a.link--strokeless {
  background-image: none;
  transition: color 0.2s ease-out; }
  a.link--strokeless:hover {
    color: #007DBA; }

.button, .pagination ul li a,
button {
  font: normal bold 1rem "Noto Sans", sans-serif;
  line-height: 1.625rem;
  text-transform: none;
  border-radius: 0;
  border-width: 0;
  display: inline-block;
  padding: 0.8125rem 1.6875rem;
  background-color: #0033A0;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-out;
  position: relative;
  overflow: hidden; }
  .button span, .pagination ul li a span,
  button span {
    position: relative;
    z-index: 2; }
  .button:after, .pagination ul li a:after,
  button:after {
    position: absolute;
    display: inline-block;
    content: '';
    z-index: 1;
    width: 1.875rem;
    height: 1.875rem;
    transform: rotate(-45deg);
    top: -0.9375rem;
    left: -0.9375rem;
    background: #007DBA;
    transition: transform 0.2s ease-out, background 0.2s ease-out; }
  .button:hover:after, .pagination ul li a:hover:after,
  button:hover:after {
    transform: rotate(-45deg) scale(20); }
  .button.button--block, .pagination ul li a.button--block,
  button.button--block {
    display: block;
    text-align: center; }

input[type=button],
input[type=submit] {
  transition: background 0.2s ease-out; }
  input[type=button]:hover, input[type=button]:active,
  input[type=submit]:hover,
  input[type=submit]:active {
    background: #007DBA; }

.button, .pagination ul li a {
  background-image: none;
  transition: color 0.2s ease-out; }
  .button:hover, .pagination ul li a:hover {
    color: #007DBA; }
  .button:link, .pagination ul li a:link, .button:visited, .pagination ul li a:visited {
    color: #fff;
    text-decoration: none; }
  .button:hover, .pagination ul li a:hover, .button:active, .pagination ul li a:active {
    color: #fff;
    text-decoration: none; }

button {
  cursor: pointer; }

.button[type=reset], .pagination ul li a[type=reset],
input[type=reset] {
  transition: background 0.2s ease-out;
  background-color: #7d8181; }
  .button[type=reset]:hover, .pagination ul li a[type=reset]:hover, .button[type=reset]:active, .pagination ul li a[type=reset]:active, .button[type=reset]:focus, .pagination ul li a[type=reset]:focus,
  input[type=reset]:hover,
  input[type=reset]:active,
  input[type=reset]:focus {
    background-color: #4b4d4d; }

.button.button--strip, .pagination ul li a.button--strip,
button.button--strip {
  display: inline;
  padding: 0;
  background: transparent;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  transition: color 0.2s ease-out; }
  .button.button--strip:after, .pagination ul li a.button--strip:after,
  button.button--strip:after {
    display: none; }

.button.button--link-style, .pagination ul li a.button--link-style,
button.button--link-style {
  display: inline;
  padding: 0;
  background: transparent;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  transition: color 0.2s ease-out;
  color: #0033A0;
  transition: color 0.2s ease-out; }
  .button.button--link-style:after, .pagination ul li a.button--link-style:after,
  button.button--link-style:after {
    display: none; }
  .button.button--link-style:hover, .pagination ul li a.button--link-style:hover, .button.button--link-style:active, .pagination ul li a.button--link-style:active, .button.button--link-style:focus, .pagination ul li a.button--link-style:focus,
  button.button--link-style:hover,
  button.button--link-style:active,
  button.button--link-style:focus {
    color: #007DBA; }

.button.button--no-triangle, .pagination ul li a.button--no-triangle,
button.button--no-triangle {
  transition: background 0.2s ease-out; }
  .button.button--no-triangle:after, .pagination ul li a.button--no-triangle:after,
  button.button--no-triangle:after {
    display: none; }
  .button.button--no-triangle:hover, .pagination ul li a.button--no-triangle:hover, .button.button--no-triangle:active, .pagination ul li a.button--no-triangle:active,
  button.button--no-triangle:hover,
  button.button--no-triangle:active {
    background: #007DBA; }

h1,
.visually-h1 {
  font: normal normal 3.75rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none;
  margin: 0 0 1rem 0; }
  @media screen and (max-width: 40em) {
    h1,
    .visually-h1 {
      font-size: 3.125rem; } }
  @media screen and (max-width: 23.75em) {
    h1,
    .visually-h1 {
      font-size: 2.375rem; } }
  h1 a,
  .visually-h1 a {
    background-position: 0 78%; }
    h1 a:hover,
    .visually-h1 a:hover {
      background-size: 100% 88%; }

h2,
.visually-h2 {
  font: normal normal 2.875rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none;
  margin: 0 0 1rem 0; }
  @media screen and (max-width: 40em) {
    h2,
    .visually-h2 {
      font-size: 2.5rem; } }
  @media screen and (max-width: 23.75em) {
    h2,
    .visually-h2 {
      font-size: 2.125rem; } }
  h2 a,
  .visually-h2 a {
    background-position: 0 78%; }
    h2 a:hover,
    .visually-h2 a:hover {
      background-size: 100% 88%; }

h3,
.visually-h3 {
  font: normal normal 2.125rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none;
  margin: 0 0 1rem 0; }
  @media screen and (max-width: 23.75em) {
    h3,
    .visually-h3 {
      font-size: 1.875rem; } }
  h3 a,
  .visually-h3 a {
    background-position: 0 78%; }
    h3 a:hover,
    .visually-h3 a:hover {
      background-size: 100% 88%; }

h4,
.visually-h4 {
  font: normal normal 1.875rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none;
  margin: 0 0 1rem 0; }
  @media screen and (max-width: 23.75em) {
    h4,
    .visually-h4 {
      font-size: 1.625rem; } }
  h4 a,
  .visually-h4 a {
    background-position: 0 78%; }
    h4 a:hover,
    .visually-h4 a:hover {
      background-size: 100% 88%; }

h5,
.visually-h5 {
  font: normal normal 1.625rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none;
  margin: 0 0 1rem 0; }
  @media screen and (max-width: 23.75em) {
    h5,
    .visually-h5 {
      font-size: 1.375rem; } }
  h5 a,
  .visually-h5 a {
    background-position: 0 78%; }
    h5 a:hover,
    .visually-h5 a:hover {
      background-size: 100% 88%; }

h6,
.visually-h6 {
  font: normal normal 1.25rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none;
  margin: 0 0 1rem 0; }
  h6 a,
  .visually-h6 a {
    background-position: 0 78%; }
    h6 a:hover,
    .visually-h6 a:hover {
      background-size: 100% 88%; }

ul, ol {
  margin-bottom: 2.5rem;
  margin-left: 3.125rem; }
  ul li, ol li {
    font-size: 1.125rem;
    margin-bottom: 1rem; }
    ul li ul, ul li ol, ol li ul, ol li ol {
      margin-top: 1rem;
      margin-left: 3.125rem; }

ul.strip-list {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0; }
  ul.strip-list > li {
    text-indent: 0;
    margin-left: 0;
    margin-bottom: 0; }
    ul.strip-list > li:before {
      display: none; }

ol.strip-list {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0; }
  ol.strip-list > li {
    text-indent: 0;
    margin-left: 0;
    margin-bottom: 0; }
    ol.strip-list > li:before {
      display: none; }

ul.no-bullets {
  list-style-type: none;
  margin-left: 0; }

ul.loose-list li, ol.loose-list li {
  margin-bottom: 1.875rem; }
  ul.loose-list li ul, ul.loose-list li ol, ol.loose-list li ul, ol.loose-list li ol {
    margin-top: 1.875rem; }

ul.tight-list li, ol.tight-list li {
  margin-bottom: 0.625rem; }
  ul.tight-list li ul, ul.tight-list li ol, ol.tight-list li ul, ol.tight-list li ol {
    margin-top: 0.625rem; }

ul.dot-list, ul.news-meta {
  list-style-type: none;
  margin-left: 0;
  margin: 0;
  padding: 0; }
  ul.dot-list li, ul.news-meta li {
    display: inline-block; }
    ul.dot-list li:after, ul.news-meta li:after {
      display: inline-block;
      content: '';
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 50%;
      background: #B1B3B3;
      margin: 0 1rem 2% 1rem; }
    ul.dot-list li:last-child::after, ul.news-meta li:last-child::after {
      display: none; }
    @media screen and (max-width: 23.75em) {
      ul.dot-list li, ul.news-meta li {
        display: block; }
        ul.dot-list li:after, ul.news-meta li:after {
          display: none; } }

dl dt,
dl dd {
  font-size: 1.125rem; }

dl dd {
  margin-bottom: 1.25rem; }

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  border: rgba(16, 24, 32, 0.25) 1px solid;
  box-shadow: none;
  margin: 0.625rem 0 2.5rem 0;
  padding: 1rem 0.875rem;
  line-height: 1em;
  height: auto; }
  [type='text']:focus,
  [type='password']:focus,
  [type='date']:focus,
  [type='datetime']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='week']:focus,
  [type='email']:focus,
  [type='number']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='url']:focus,
  [type='color']:focus,
  textarea:focus,
  select:focus {
    border: #101820 1px solid;
    box-shadow: 0 0 0; }
  [type='text'][disabled],
  [type='password'][disabled],
  [type='date'][disabled],
  [type='datetime'][disabled],
  [type='datetime-local'][disabled],
  [type='month'][disabled],
  [type='week'][disabled],
  [type='email'][disabled],
  [type='number'][disabled],
  [type='search'][disabled],
  [type='tel'][disabled],
  [type='time'][disabled],
  [type='url'][disabled],
  [type='color'][disabled],
  textarea[disabled],
  select[disabled] {
    background: #D9D9D6;
    color: #a4a6a6; }

label > [type='checkbox'],
label > [type='radio'] {
  margin: 0;
  cursor: pointer; }

ul.form-widget-list {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0; }
  ul.form-widget-list > li {
    text-indent: 0;
    margin-left: 0;
    margin-bottom: 0; }
    ul.form-widget-list > li:before {
      display: none; }
  ul.form-widget-list li {
    margin-bottom: 1rem; }
    ul.form-widget-list li label {
      display: inline-flex;
      align-items: baseline;
      cursor: pointer; }
      ul.form-widget-list li label [type='checkbox'],
      ul.form-widget-list li label [type='radio'] {
        margin-right: 0.625rem; }

fieldset legend {
  margin-bottom: 1.25rem; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(16, 24, 32, 0.7); }

input[disabled]::-webkit-input-placeholder,
textarea[disabled]::-webkit-input-placeholder {
  color: #a4a6a6; }

select {
  padding-right: 1.875rem; }

label {
  color: #101820;
  font-size: 1rem;
  line-height: 1.625rem; }

.is-error,
.is-valid,
fieldset[disabled] {
  border-width: 2px;
  border-style: solid; }

.is-error {
  border-color: #DA291C; }

.is-valid {
  border-color: #348338; }

fieldset.is-error, fieldset.is-valid, fieldset[disabled] {
  padding: 1.25rem; }
  fieldset.is-error legend, fieldset.is-valid legend, fieldset[disabled] legend {
    padding: 0 0.625rem;
    margin-bottom: 0; }

fieldset[disabled] {
  border-color: #B1B3B3;
  color: #a4a6a6;
  cursor: not-allowed; }
  fieldset[disabled] label {
    color: #a4a6a6;
    cursor: not-allowed; }
  fieldset[disabled] label > [type='checkbox'],
  fieldset[disabled] label > [type='radio'] {
    cursor: not-allowed; }
  fieldset[disabled] ul.form-widget-list li label {
    cursor: not-allowed; }

label abbr[title="Required"],
legend abbr[title="Required"] {
  border: 0;
  color: #DA291C;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 0px; }

table {
  border-collapse: collapse;
  border: 0;
  margin-bottom: 2.5rem; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td,
  table tbody th,
  table tbody td {
    padding: 1rem 1.25rem; }
  table thead tr th,
  table tfoot tr th {
    text-align: left; }
  table thead tr th {
    background: #007DBA;
    border: rgba(0, 0, 0, 0.2) 1px solid;
    color: #fff; }
  table tfoot tr th {
    background: #D0D0CE;
    border: rgba(0, 0, 0, 0.1) 1px solid;
    color: #101820; }
  table tbody tr,
  table tbody tr:nth-child(even) {
    background: #fff; }
    table tbody tr th,
    table tbody tr:nth-child(even) th {
      text-align: left; }
    table tbody tr th,
    table tbody tr td,
    table tbody tr:nth-child(even) th,
    table tbody tr:nth-child(even) td {
      border: #D9D9D6 1px solid; }
  table caption {
    margin-bottom: 1.25rem; }

table.table--zebra-stripe tbody tr:nth-child(even) {
  background: #F3F3F2; }

@media screen and (max-width: 63.9375em) {
  .table-responsive-wrap {
    overflow-x: auto; } }

a.logo {
  background-image: none;
  transition: color 0.2s ease-out; }
  a.logo:hover {
    color: #007DBA; }

.icon {
  height: 1em;
  width: 1em;
  display: inline-block; }

.embedded-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: ratio-to-percentage(4 by 3);
  overflow: hidden; }
  .embedded-video iframe,
  .embedded-video object,
  .embedded-video embed,
  .embedded-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.embedded-widescreen-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: ratio-to-percentage(16 by 9);
  overflow: hidden; }
  .embedded-widescreen-video iframe,
  .embedded-widescreen-video object,
  .embedded-widescreen-video embed,
  .embedded-widescreen-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

img {
  width: 100%;
  height: auto;
  display: inline-block; }

figure img {
  width: 100%;
  height: auto; }

.described-links__item {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.intro p,
.intro h1, .intro h2, .intro h3, .intro h4, .intro h5, .intro h6 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.75rem;
  line-height: 2.375rem; }
  @media screen and (max-width: 23.75em) {
    .intro p,
    .intro h1, .intro h2, .intro h3, .intro h4, .intro h5, .intro h6 {
      font-size: 1.5rem;
      line-height: 2.125rem; } }

.pullquote {
  margin-bottom: 2.5rem; }
  .pullquote p {
    margin-bottom: 1.25rem;
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.75rem;
    line-height: 2.375rem; }
    @media screen and (max-width: 23.75em) {
      .pullquote p {
        font-size: 1.5rem;
        line-height: 2.125rem; } }

.headline-group .superhead,
.headline-group .subhead {
  display: block;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.375rem;
  line-height: 2rem; }

.headline-group .superhead {
  text-transform: uppercase;
  margin-bottom: 0.625rem; }

.headline-group .subhead {
  margin-top: 0.625rem; }

.headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level1 {
  font: normal normal 3.75rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }
  @media screen and (max-width: 40em) {
    .headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level1 {
      font-size: 3.125rem; } }
  @media screen and (max-width: 23.75em) {
    .headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level1 {
      font-size: 2.375rem; } }

.headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level2 {
  font: normal normal 2.875rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }
  @media screen and (max-width: 40em) {
    .headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level2 {
      font-size: 2.5rem; } }
  @media screen and (max-width: 23.75em) {
    .headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level2 {
      font-size: 2.125rem; } }

.headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level3 {
  font: normal normal 2.125rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }
  @media screen and (max-width: 23.75em) {
    .headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level3 {
      font-size: 1.875rem; } }

.headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level4 {
  font: normal normal 1.875rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }
  @media screen and (max-width: 23.75em) {
    .headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level4 {
      font-size: 1.625rem; } }

.headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level5 {
  font: normal normal 1.625rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }
  @media screen and (max-width: 23.75em) {
    .headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level5 {
      font-size: 1.375rem; } }

.headline-group.headline-group--nonsemantic.headline-group--nonsemantic-level6 {
  font: normal normal 1.25rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }

.headline-group.headline-group--simple-superhead .superhead {
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  text-transform: none; }

.list-header {
  border-width: 0;
  border-style: solid;
  border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#F2A900'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
  border-bottom-width: 18px; }
  .list-header .headline-group {
    margin-bottom: 0; }

.section-header {
  margin-bottom: 2.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  @media screen and (max-width: 23.75em) {
    .section-header {
      margin-bottom: 1.25rem;
      flex-flow: column nowrap; } }
  .section-header .headline-group {
    margin-right: 1.25rem; }
    @media screen and (max-width: 23.75em) {
      .section-header .headline-group {
        margin-right: 0; } }
  .section-header .section-header__button button,
  .section-header .section-header__button .button,
  .section-header .section-header__button .pagination ul li a,
  .pagination ul li .section-header .section-header__button a {
    margin-bottom: 0; }

.global-main-nav ul.global-main-nav__list {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0;
  display: flex; }
  .global-main-nav ul.global-main-nav__list > li {
    text-indent: 0;
    margin-left: 0;
    margin-bottom: 0; }
    .global-main-nav ul.global-main-nav__list > li:before {
      display: none; }
  .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item {
    position: relative; }
    .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item:last-child {
      margin-right: 0; }
    .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item a.global-main-nav__parent-link {
      background-image: none;
      transition: color 0.2s ease-out;
      font-family: "Barlow Condensed", sans-serif;
      font-size: 1.75rem;
      padding: 1.25rem 2.5rem 1.25rem 0; }
      .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item a.global-main-nav__parent-link:hover {
        color: #007DBA; }
    .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__parent-link {
      display: inline;
      padding: 0;
      background: transparent;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      transition: color 0.2s ease-out;
      font-family: "Barlow Condensed", sans-serif;
      font-size: 1.75rem; }
      .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__parent-link:after {
        display: none; }
    .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle {
      display: none; }

.global-main-nav .global-main-nav__dropdown {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  padding-top: 0.625rem;
  transition: all 0.2s ease-out; }
  .global-main-nav .global-main-nav__dropdown ul {
    margin: 0 0 0 0;
    list-style: none;
    padding: 0 0 0 0;
    background: #012169;
    border-top: 0.25rem #41B6E6 solid;
    padding: 1.875rem;
    width: 15.625rem; }
    .global-main-nav .global-main-nav__dropdown ul > li {
      text-indent: 0;
      margin-left: 0;
      margin-bottom: 0; }
      .global-main-nav .global-main-nav__dropdown ul > li:before {
        display: none; }
    .global-main-nav .global-main-nav__dropdown ul li {
      font-weight: bold;
      font-size: 0.875rem;
      margin-bottom: 1.375rem; }
      .global-main-nav .global-main-nav__dropdown ul li:last-child {
        margin-bottom: 0; }
      .global-main-nav .global-main-nav__dropdown ul li a {
        background-image: none;
        transition: color 0.2s ease-out;
        color: #fff; }
        .global-main-nav .global-main-nav__dropdown ul li a:hover {
          color: #007DBA; }
        .global-main-nav .global-main-nav__dropdown ul li a:hover, .global-main-nav .global-main-nav__dropdown ul li a:active, .global-main-nav .global-main-nav__dropdown ul li a:focus {
          color: #F2A900; }

.global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item.toggleDropdown--active a.global-main-nav__parent-link {
  color: #007DBA; }

.global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item.toggleDropdown--active .global-main-nav__dropdown {
  opacity: 1;
  visibility: visible; }

@media screen and (max-width: 63.9375em) {
  .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-bottom: #D9D9D6 1px solid; }
    .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item a.global-main-nav__parent-link {
      padding: 0.625rem 1.875rem; }
    .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle {
      display: inline;
      padding: 0;
      background: transparent;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      transition: color 0.2s ease-out;
      color: #0033A0;
      transition: color 0.2s ease-out;
      display: inline-block;
      padding: 0 1.25rem; }
      .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle:after {
        display: none; }
      .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle:hover, .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle:active, .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle:focus {
        color: #007DBA; }
      .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle svg {
        transform: rotate(90deg);
        transition: transform 0.2s ease-out; }
      .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle.active {
        background: #41B6E6; }
        .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item button.global-main-nav__mobile-dropdown-toggle.active svg {
          transform: rotate(-90deg);
          color: #fff; }
    .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item .global-main-nav__dropdown {
      flex: 0 1 100%; } }

.global-utility-nav ul {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0;
  display: flex; }
  .global-utility-nav ul > li {
    text-indent: 0;
    margin-left: 0;
    margin-bottom: 0; }
    .global-utility-nav ul > li:before {
      display: none; }
  .global-utility-nav ul li {
    margin-right: 1.875rem;
    font-size: 0.875rem;
    font-weight: bold; }
    .global-utility-nav ul li:last-child {
      margin-right: 0; }
    .global-utility-nav ul li button {
      display: inline;
      padding: 0;
      background: transparent;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      transition: color 0.2s ease-out;
      color: #012169; }
      .global-utility-nav ul li button:after {
        display: none; }
      .global-utility-nav ul li button.toggleSearch {
        display: flex;
        align-items: center; }
        .global-utility-nav ul li button.toggleSearch svg {
          margin-left: 0.625rem;
          width: 1.3em;
          height: 1.3em; }
          .global-utility-nav ul li button.toggleSearch svg.close {
            display: none; }
        .global-utility-nav ul li button.toggleSearch.toggleSearch--active svg.close {
          display: inline-block; }
        .global-utility-nav ul li button.toggleSearch.toggleSearch--active svg.search {
          display: none; }

.global-utility-nav .search-bar {
  display: none; }

@media screen and (max-width: 63.9375em) {
  .global-utility-nav {
    background: #F3F3F2;
    padding: 1.875rem; }
    .global-utility-nav ul {
      flex-flow: row wrap; }
      .global-utility-nav ul li {
        flex: 0 1 100%;
        margin-bottom: 1.875rem; }
        .global-utility-nav ul li:last-child {
          margin-bottom: 0; }
        .global-utility-nav ul li button.toggleSearch {
          display: none; }
    .global-utility-nav .search-bar {
      display: block; } }

.global-mobile-toggle span.hamburger-icon {
  margin: 0 auto 0.625rem auto;
  display: block;
  position: relative;
  width: 1.625rem;
  height: 1.625rem; }
  .global-mobile-toggle span.hamburger-icon span {
    display: block;
    position: absolute;
    height: 0.1875rem;
    width: 100%;
    background: #fff;
    border-radius: 0.5625rem;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out; }
    .global-mobile-toggle span.hamburger-icon span:nth-child(1) {
      top: 0; }
    .global-mobile-toggle span.hamburger-icon span:nth-child(2), .global-mobile-toggle span.hamburger-icon span:nth-child(3) {
      top: 0.71875rem; }
    .global-mobile-toggle span.hamburger-icon span:nth-child(4) {
      bottom: 0; }

.global-mobile-toggle button {
  display: inline;
  padding: 0;
  background: transparent;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  transition: color 0.2s ease-out;
  color: #012169;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 0.625rem; }
  .global-mobile-toggle button:after {
    display: none; }
  .global-mobile-toggle button span.hamburger-icon span {
    background-color: #012169; }
  .global-mobile-toggle button:hover, .global-mobile-toggle button:focus {
    color: #012169; }
    .global-mobile-toggle button:hover span.hamburger-icon span, .global-mobile-toggle button:focus span.hamburger-icon span {
      background-color: #007DBA; }

body.mobile-nav-open .global-mobile-toggle span.hamburger-icon span:nth-child(1), body.mobile-nav-open .global-mobile-toggle span.hamburger-icon span:nth-child(4) {
  top: 0.71875rem;
  width: 0%;
  left: 50%; }

body.mobile-nav-open .global-mobile-toggle span.hamburger-icon span:nth-child(2) {
  transform: rotate(45deg); }

body.mobile-nav-open .global-mobile-toggle span.hamburger-icon span:nth-child(3) {
  transform: rotate(-45deg); }

.secondary-nav {
  margin-bottom: 3.75rem; }
  .secondary-nav ul {
    margin: 0 0 0 0;
    list-style: none;
    padding: 0 0 0 0; }
    .secondary-nav ul > li {
      text-indent: 0;
      margin-left: 0;
      margin-bottom: 0; }
      .secondary-nav ul > li:before {
        display: none; }
    .secondary-nav ul li {
      margin-bottom: 0.25rem; }
      .secondary-nav ul li a {
        font-family: "Barlow Condensed", sans-serif;
        font-size: 1.375rem;
        font-weight: normal;
        padding: 1.25rem 2.1875rem;
        display: block;
        background-color: #007DBA; }
        .secondary-nav ul li a:after {
          background-color: #41B6E6; }
        .secondary-nav ul li a:hover, .secondary-nav ul li a:active, .secondary-nav ul li a:focus, .secondary-nav ul li a.active {
          color: #101820; }
        .secondary-nav ul li a.active {
          background-color: #41B6E6; }
  .secondary-nav.secondary-nav--alternate ul li a {
    background-color: #0033A0; }
    .secondary-nav.secondary-nav--alternate ul li a:after {
      background-color: #007DBA; }
    .secondary-nav.secondary-nav--alternate ul li a:hover, .secondary-nav.secondary-nav--alternate ul li a:active, .secondary-nav.secondary-nav--alternate ul li a:focus, .secondary-nav.secondary-nav--alternate ul li a.active {
      color: #fff; }
    .secondary-nav.secondary-nav--alternate ul li a.active {
      background-color: #007DBA; }

.skip-nav {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: -5rem; }
  .skip-nav:focus {
    top: 0;
    transition: top 0.5s; }

.breadcrumbs ul {
  list-style-type: none;
  margin-left: 0;
  margin: 0;
  padding: 0; }
  .breadcrumbs ul li {
    display: inline-block; }
    .breadcrumbs ul li:after {
      display: inline-block;
      content: '';
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 50%;
      background: #B1B3B3;
      margin: 0 1rem 2% 1rem; }
    .breadcrumbs ul li:last-child::after {
      display: none; }
    @media screen and (max-width: 23.75em) {
      .breadcrumbs ul li {
        display: block; }
        .breadcrumbs ul li:after {
          display: none; } }
  .breadcrumbs ul li {
    font-weight: bold; }

.pagination {
  margin-bottom: 2.5rem; }
  .pagination ul {
    margin: 0; }
    .pagination ul li {
      display: inline-block;
      margin: 0; }
      .pagination ul li a {
        transition: background 0.2s ease-out;
        margin-bottom: 0.625rem;
        text-align: center;
        padding: 0.3125rem 0.9375rem; }
        .pagination ul li a:after {
          display: none; }
        .pagination ul li a:hover, .pagination ul li a:active {
          background: #007DBA; }
        .pagination ul li a:before, .pagination ul li a:after {
          color: #fff; }
      .pagination ul li.current a {
        background-color: #41B6E6;
        color: #101820; }
      @media screen and (max-width: 23.75em) {
        .pagination ul li.first, .pagination ul li.previous, .pagination ul li.next, .pagination ul li.last {
          display: block; } }
      .pagination ul li.first a:hover:before, .pagination ul li.first a:hover:after, .pagination ul li.first a:active:before, .pagination ul li.first a:active:after, .pagination ul li.first a:focus:before, .pagination ul li.first a:focus:after, .pagination ul li.previous a:hover:before, .pagination ul li.previous a:hover:after, .pagination ul li.previous a:active:before, .pagination ul li.previous a:active:after, .pagination ul li.previous a:focus:before, .pagination ul li.previous a:focus:after, .pagination ul li.next a:hover:before, .pagination ul li.next a:hover:after, .pagination ul li.next a:active:before, .pagination ul li.next a:active:after, .pagination ul li.next a:focus:before, .pagination ul li.next a:focus:after, .pagination ul li.last a:hover:before, .pagination ul li.last a:hover:after, .pagination ul li.last a:active:before, .pagination ul li.last a:active:after, .pagination ul li.last a:focus:before, .pagination ul li.last a:focus:after {
        color: #fff; }
      .pagination ul li.first a:before, .pagination ul li.previous a:before, .pagination ul li.next a:before, .pagination ul li.last a:before {
        margin-right: 0.3125rem; }
      .pagination ul li.first a:after, .pagination ul li.previous a:after, .pagination ul li.next a:after, .pagination ul li.last a:after {
        margin-left: 0.3125rem; }
      @media screen and (max-width: 23.75em) {
        .pagination ul li.first a, .pagination ul li.previous a, .pagination ul li.next a, .pagination ul li.last a {
          width: 100%; } }
      .pagination ul li.first.disabled, .pagination ul li.previous.disabled, .pagination ul li.next.disabled, .pagination ul li.last.disabled {
        cursor: default;
        padding: 0; }
        .pagination ul li.first.disabled a, .pagination ul li.previous.disabled a, .pagination ul li.next.disabled a, .pagination ul li.last.disabled a {
          cursor: default;
          background-color: #B1B3B3;
          color: #101820; }
          .pagination ul li.first.disabled a:before, .pagination ul li.first.disabled a:after, .pagination ul li.previous.disabled a:before, .pagination ul li.previous.disabled a:after, .pagination ul li.next.disabled a:before, .pagination ul li.next.disabled a:after, .pagination ul li.last.disabled a:before, .pagination ul li.last.disabled a:after {
            color: #101820; }
      .pagination ul li.first a:before {
        content: '≪'; }
      .pagination ul li.previous a:before {
        content: '<'; }
      .pagination ul li.next a:after {
        content: '>'; }
      .pagination ul li.last a:after {
        content: '≫'; }
      .pagination ul li.next a:after,
      .pagination ul li.last a:after {
        display: inline-block;
        transform: none;
        position: static;
        background: none;
        width: auto;
        height: auto; }

.social-media a {
  background-image: none;
  transition: color 0.2s ease-out;
  color: #007DBA;
  margin-right: 0.3125rem; }
  .social-media a:hover {
    color: #007DBA; }
  .social-media a:last-child {
    margin-right: 0; }
  .social-media a:hover, .social-media a:active, .social-media a:focus {
    color: #0033A0; }
  .social-media a svg {
    width: 1.625rem;
    height: 1.625rem; }

.link-collection {
  margin-bottom: 2.5rem; }
  .link-collection ul {
    list-style-type: none;
    margin-left: 0; }
    .link-collection ul ul {
      margin-left: 3.125rem; }
  .link-collection .link-collection__headline {
    font-family: "Barlow Condensed", sans-serif; }
  .link-collection.link-collection--two-column ul {
    column-count: 2;
    column-gap: 2.5rem; }
    @media screen and (max-width: 63.9375em) {
      .link-collection.link-collection--two-column ul {
        column-gap: 1.875rem; } }
    @media screen and (max-width: 40em) {
      .link-collection.link-collection--two-column ul {
        column-count: 1; } }
    .link-collection.link-collection--two-column ul li {
      break-inside: avoid;
      page-break-inside: avoid; }
    .link-collection.link-collection--two-column ul ul {
      column-count: 1; }

.search-bar form {
  display: flex; }
  .search-bar form input[type="text"] {
    margin-bottom: 0;
    margin-top: 0;
    height: auto; }
  .search-bar form button {
    transition: background 0.2s ease-out; }
    .search-bar form button:after {
      display: none; }
    .search-bar form button:hover, .search-bar form button:active {
      background: #007DBA; }
    .search-bar form button svg {
      color: #fff; }

.global-search {
  background: #F3F3F2;
  padding: 1rem 1rem; }
  @media screen and (min-width: 35rem) {
    .global-search {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 48rem) {
    .global-search {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 89rem) {
    .global-search {
      padding: 2rem 0; } }
  .global-search .search-bar {
    max-width: 85rem;
    margin: 0 auto; }
    .global-search .search-bar > :first-child {
      margin-top: 0; }
    .global-search .search-bar > :last-child {
      margin-bottom: 0; }

.fact .fact__headline {
  margin-bottom: 1rem; }
  .fact .fact__headline .head {
    font-weight: bold; }
  .fact .fact__headline .subhead {
    margin-top: 0; }

.media .figure-wrapper a {
  display: inline-block;
  background-image: none;
  transition: color 0.2s ease-out;
  background-color: #0033A0; }
  .media .figure-wrapper a:hover {
    color: #007DBA; }
  .media .figure-wrapper a img {
    transition: opacity 0.2s ease-out; }
  .media .figure-wrapper a:hover img, .media .figure-wrapper a:active img, .media .figure-wrapper a:focus img {
    opacity: 0.75; }

.media.media--video-indicator .figure-wrapper a {
  position: relative; }
  .media.media--video-indicator .figure-wrapper a:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 3.125rem;
    height: 3.125rem;
    background: url("../images/play.svg") no-repeat center center; }

.nice-video {
  position: relative;
  background-color: #000; }
  .nice-video .embedded-video,
  .nice-video .embedded-widescreen-video {
    margin-bottom: 0; }
  .nice-video button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms; }

.nice-video__preview {
  background-color: #000;
  background-image: linear-gradient(180deg, #101820 0%, #000 100%);
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 300ms;
  overflow: hidden; }
  .nice-video__preview img {
    width: 100%;
    height: auto; }

.nice-video__transcript {
  padding: 0.2rem 1rem;
  text-align: center; }
  .nice-video__transcript a {
    color: #fff; }

.nice-video--ready .nice-video__preview {
  z-index: 10;
  opacity: 1;
  pointer-events: auto; }

.nice-video--ready button {
  opacity: 1;
  z-index: 10;
  pointer-events: auto; }

.nice-video--playing .nice-video__preview {
  opacity: 0;
  pointer-events: none; }

.nice-video--playing button {
  opacity: 0;
  pointer-events: none; }

.teaser {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start; }
  @media screen and (min-width: 40em) {
    .teaser .teaser__body {
      order: 2; } }
  @media screen and (min-width: 40em) {
    .teaser .teaser__media {
      order: 1; } }
  .teaser.teaser--background .teaser__body, .teaser.teaser--highlight .teaser__body, .teaser.teaser--profile .teaser__body, .teaser.teaser--media-offset .teaser__body {
    background-color: #F3F3F2;
    padding: 2.5rem; }
    @media screen and (max-width: 23.75em) {
      .teaser.teaser--background .teaser__body, .teaser.teaser--highlight .teaser__body, .teaser.teaser--profile .teaser__body, .teaser.teaser--media-offset .teaser__body {
        padding: 1.25rem; } }
  .teaser.teaser--highlight .teaser__body {
    border-top: 0.25rem #C6007E solid; }
  .teaser.teaser--profile .teaser__body {
    border-top: 0.25rem #C35413 solid; }
  .teaser.teaser--media-row {
    flex-flow: row nowrap;
    justify-content: space-between; }
    @media screen and (max-width: 40em) {
      .teaser.teaser--media-row {
        flex-flow: row wrap; } }
    .teaser.teaser--media-row .teaser__body,
    .teaser.teaser--media-row .teaser__media {
      flex: 0 1 48%; }
      @media screen and (max-width: 40em) {
        .teaser.teaser--media-row .teaser__body,
        .teaser.teaser--media-row .teaser__media {
          flex: 0 1 100%; } }
    .teaser.teaser--media-row .teaser__body {
      order: 1; }
    .teaser.teaser--media-row .teaser__media {
      order: 2; }
    .teaser.teaser--media-row.teaser--media-row-invert .teaser__body {
      order: 2; }
    .teaser.teaser--media-row.teaser--media-row-invert .teaser__media {
      order: 1; }
    .teaser.teaser--media-row.teaser--media-row-sixty-forty .teaser__body {
      flex: 0 1 58%; }
    .teaser.teaser--media-row.teaser--media-row-sixty-forty .teaser__media {
      flex: 0 1 38%; }
    @media screen and (max-width: 40em) {
      .teaser.teaser--media-row.teaser--media-row-sixty-forty .teaser__body,
      .teaser.teaser--media-row.teaser--media-row-sixty-forty .teaser__media {
        flex: 0 1 100%; } }
  .teaser.teaser--media-offset {
    align-items: center; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .teaser.teaser--media-offset {
        padding-top: 2.5rem; } }
    .teaser.teaser--media-offset .teaser__body,
    .teaser.teaser--media-offset .teaser__media {
      flex: 0 1 50%;
      position: relative; }
      @media screen and (max-width: 40em) {
        .teaser.teaser--media-offset .teaser__body,
        .teaser.teaser--media-offset .teaser__media {
          flex: 0 1 100%; } }
    .teaser.teaser--media-offset .teaser__body {
      z-index: 2; }
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        .teaser.teaser--media-offset .teaser__body {
          flex: 0 1 60%; } }
    .teaser.teaser--media-offset .teaser__media {
      z-index: 1; }
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        .teaser.teaser--media-offset .teaser__media {
          flex: 0 1 40%;
          margin-top: -2.5rem;
          align-self: flex-start; } }
      .teaser.teaser--media-offset .teaser__media .media {
        margin-right: -10%; }
        @media screen and (max-width: 40em) {
          .teaser.teaser--media-offset .teaser__media .media {
            margin-right: 0; } }
    .teaser.teaser--media-offset.teaser--media-offset-invert .teaser__body {
      order: 1; }
    .teaser.teaser--media-offset.teaser--media-offset-invert .teaser__media {
      order: 2; }
      .teaser.teaser--media-offset.teaser--media-offset-invert .teaser__media .media {
        margin-left: -10%;
        margin-right: 0; }
        @media screen and (max-width: 40em) {
          .teaser.teaser--media-offset.teaser--media-offset-invert .teaser__media .media {
            margin-left: 0; } }
  .teaser.teaser--edge {
    padding: 1rem 1rem; }
    @media screen and (min-width: 35rem) {
      .teaser.teaser--edge {
        padding: 1.5rem 2rem; } }
    @media screen and (min-width: 48rem) {
      .teaser.teaser--edge {
        padding: 1.5rem 2rem; } }
    @media screen and (min-width: 89rem) {
      .teaser.teaser--edge {
        padding: 2rem 0; } }
    .teaser.teaser--edge .teaser__body {
      max-width: 85rem;
      margin: 0 auto; }
      .teaser.teaser--edge .teaser__body > :first-child {
        margin-top: 0; }
      .teaser.teaser--edge .teaser__body > :last-child {
        margin-bottom: 0; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .teaser.teaser--edge.teaser--media-offset {
        margin-top: 2.5rem; } }
    .teaser.teaser--edge.teaser--media-offset .teaser__body > * {
      max-width: 40rem; }

.simple-block .simple-block__headline {
  font: normal normal 2.125rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }
  @media screen and (max-width: 23.75em) {
    .simple-block .simple-block__headline {
      font-size: 1.875rem; } }

.simple-block.simple-block--shaded, .simple-block.simple-block--stroke, .simple-block.simple-block--bright {
  padding: 1.875rem; }

.simple-block.simple-block--shaded, .simple-block.simple-block--stroke {
  background-color: #F3F3F2; }

.simple-block.simple-block--bright {
  background-color: #F2A900; }

.simple-block.simple-block--stroke {
  border-top: 0.25rem #41B6E6 solid; }

ul.news-meta {
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  text-transform: none; }
  ul.news-meta li {
    margin-bottom: 0;
    font-size: inherit;
    line-height: inherit; }

.story-header .headline-group .superhead {
  margin-bottom: 0; }
  @media screen and (max-width: 23.75em) {
    .story-header .headline-group .superhead {
      margin-bottom: 1.875rem; } }

.story-header .headline-group .head {
  font: normal normal 2.875rem "Spectral", serif;
  line-height: 1.2;
  text-transform: none; }
  @media screen and (max-width: 40em) {
    .story-header .headline-group .head {
      font-size: 2.5rem; } }
  @media screen and (max-width: 23.75em) {
    .story-header .headline-group .head {
      font-size: 2.125rem; } }

.story-header .headline-group .subhead {
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: 1.875rem; }
  @media screen and (max-width: 23.75em) {
    .story-header .headline-group .subhead {
      margin-top: 1.25rem; } }

.slab {
  padding: 1rem 1rem; }
  @media screen and (min-width: 35rem) {
    .slab {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 48rem) {
    .slab {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 89rem) {
    .slab {
      padding: 2rem 0; } }

.slab__wrapper {
  max-width: 85rem;
  margin: 0 auto; }
  .slab__wrapper > :first-child {
    margin-top: 0; }
  .slab__wrapper > :last-child {
    margin-bottom: 0; }

.slab.slab--dark {
  background: #012169;
  color: #fff; }
  .slab.slab--dark abbr {
    color: #fff; }
  .slab.slab--dark a:link, .slab.slab--dark a:visited {
    color: #fff; }
  .slab.slab--dark a.link--fancy:before, .slab.slab--dark .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .slab.slab--dark a:before {
    border-color: #fff;
    background-image: url("../images/fancy-link-angle-white.svg"); }
  .slab.slab--dark a.link--fancy:hover, .slab.slab--dark .link-collection.link-collection--fancy a:hover, .link-collection.link-collection--fancy .slab.slab--dark a:hover, .slab.slab--dark a.link--fancy:active, .slab.slab--dark .link-collection.link-collection--fancy a:active, .link-collection.link-collection--fancy .slab.slab--dark a:active, .slab.slab--dark a.link--fancy:focus, .slab.slab--dark .link-collection.link-collection--fancy a:focus, .link-collection.link-collection--fancy .slab.slab--dark a:focus {
    color: #F2A900; }
    .slab.slab--dark a.link--fancy:hover:before, .slab.slab--dark .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .slab.slab--dark a:hover:before, .slab.slab--dark a.link--fancy:active:before, .slab.slab--dark .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .slab.slab--dark a:active:before, .slab.slab--dark a.link--fancy:focus:before, .slab.slab--dark .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .slab.slab--dark a:focus:before {
      border-color: #F2A900; }
  .slab.slab--dark a.link--fancy.link--fancy-filled:before, .slab.slab--dark .link-collection.link-collection--fancy a.link--fancy-filled:before, .link-collection.link-collection--fancy .slab.slab--dark a.link--fancy-filled:before {
    background-color: #007DBA; }
  .slab.slab--dark a.link--fancy.link--fancy-filled:hover:before, .slab.slab--dark .link-collection.link-collection--fancy a.link--fancy-filled:hover:before, .link-collection.link-collection--fancy .slab.slab--dark a.link--fancy-filled:hover:before, .slab.slab--dark a.link--fancy.link--fancy-filled:active:before, .slab.slab--dark .link-collection.link-collection--fancy a.link--fancy-filled:active:before, .link-collection.link-collection--fancy .slab.slab--dark a.link--fancy-filled:active:before, .slab.slab--dark a.link--fancy.link--fancy-filled:focus:before, .slab.slab--dark .link-collection.link-collection--fancy a.link--fancy-filled:focus:before, .link-collection.link-collection--fancy .slab.slab--dark a.link--fancy-filled:focus:before {
    background-color: #41B6E6; }
  .slab.slab--dark blockquote {
    border-color: #fff; }
  .slab.slab--dark hr {
    border-color: rgba(255, 255, 255, 0.2); }
  .slab.slab--dark table {
    color: #101820; }
    .slab.slab--dark table caption {
      color: #fff; }
  .slab.slab--dark button,
  .slab.slab--dark .button,
  .slab.slab--dark .pagination ul li a,
  .pagination ul li .slab.slab--dark a {
    background-color: #007DBA; }
    .slab.slab--dark button:after,
    .slab.slab--dark .button:after,
    .slab.slab--dark .pagination ul li a:after,
    .pagination ul li .slab.slab--dark a:after {
      background-color: #41B6E6; }
    .slab.slab--dark button:hover, .slab.slab--dark button:active,
    .slab.slab--dark .button:hover,
    .slab.slab--dark .pagination ul li a:hover,
    .pagination ul li .slab.slab--dark a:hover,
    .slab.slab--dark .button:active,
    .slab.slab--dark .pagination ul li a:active,
    .pagination ul li .slab.slab--dark a:active {
      color: #101820; }
  .slab.slab--dark .social-media a {
    color: #41B6E6; }
    .slab.slab--dark .social-media a:hover, .slab.slab--dark .social-media a:active, .slab.slab--dark .social-media a:focus {
      color: #fff; }
  .slab.slab--dark .breadcrumbs {
    color: #F2A900; }

.slab.slab--bright {
  background: #F2A900; }
  .slab.slab--bright a.link--fancy:before, .slab.slab--bright .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .slab.slab--bright a:before {
    border-color: #fff; }
  .slab.slab--bright a.link--fancy:hover:before, .slab.slab--bright .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .slab.slab--bright a:hover:before, .slab.slab--bright a.link--fancy:active:before, .slab.slab--bright .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .slab.slab--bright a:active:before, .slab.slab--bright a.link--fancy:focus:before, .slab.slab--bright .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .slab.slab--bright a:focus:before {
    border-color: #007DBA; }
  .slab.slab--bright hr {
    border-color: rgba(255, 255, 255, 0.5); }
    .slab.slab--bright hr.hr--dots {
      border-width: 0;
      border-style: solid;
      border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
      border-bottom-width: 18px; }
  .slab.slab--bright button,
  .slab.slab--bright .button,
  .slab.slab--bright .pagination ul li a,
  .pagination ul li .slab.slab--bright a {
    background-color: #C35413; }
    .slab.slab--bright button:after,
    .slab.slab--bright .button:after,
    .slab.slab--bright .pagination ul li a:after,
    .pagination ul li .slab.slab--bright a:after {
      background-color: #D3710C; }
  .slab.slab--bright ul.dot-list li::after, .slab.slab--bright ul.news-meta li::after {
    background-color: #fff; }
  .slab.slab--bright table thead tr th {
    background-color: #C35413; }
  .slab.slab--bright .social-media a {
    color: #012169; }
    .slab.slab--bright .social-media a:hover, .slab.slab--bright .social-media a:active, .slab.slab--bright .social-media a:focus {
      color: #0033A0; }
  .slab.slab--bright .breadcrumbs ul li::after {
    background-color: #fff; }
  .slab.slab--bright .list-header {
    border-width: 0;
    border-style: solid;
    border-image: url("data:image/svg+xml;utf8,<svg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><ellipse cx='2.25' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='2.25' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='22.5' rx='2.25' ry='2.25'/><ellipse cx='2.25' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='22.5' cy='42.75' rx='2.25' ry='2.25'/><ellipse cx='42.75' cy='42.75' rx='2.25' ry='2.25'/></g></svg>") 33% round;
    border-bottom-width: 18px; }

.sidebar-layout {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  .sidebar-layout .sidebar-layout__main {
    flex: 0 1 69%; }
  .sidebar-layout .sidebar-layout__sidebar {
    flex: 0 1 24%; }
  @media screen and (max-width: 63.9375em) {
    .sidebar-layout .sidebar-layout__main,
    .sidebar-layout .sidebar-layout__sidebar {
      flex: 0 1 100%; } }
  @media screen and (max-width: 63.9375em) {
    .sidebar-layout.sidebar-layout--mobile-swap .sidebar-layout__main {
      order: 2; }
    .sidebar-layout.sidebar-layout--mobile-swap .sidebar-layout__sidebar {
      order: 1; } }

header.main-header {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  flex-flow: row wrap;
  z-index: 2;
  background: #fff; }
  header.main-header a,
  header.main-header button {
    background-image: none;
    transition: color 0.2s ease-out;
    color: #012169; }
    header.main-header a:hover,
    header.main-header button:hover {
      color: #007DBA; }
    header.main-header a:link, header.main-header a:visited,
    header.main-header button:link,
    header.main-header button:visited {
      color: #012169; }
    header.main-header a:hover, header.main-header a:active, header.main-header a:focus,
    header.main-header button:hover,
    header.main-header button:active,
    header.main-header button:focus {
      color: #007DBA; }
  header.main-header .main-header__wrapper,
  header.main-header .global-search {
    flex: 1 0 100%; }
  header.main-header .main-header__wrapper {
    padding: 1rem 1rem;
    order: 2;
    transition: all 0.2s ease-out; }
    @media screen and (min-width: 35rem) {
      header.main-header .main-header__wrapper {
        padding: 1.5rem 2rem; } }
    @media screen and (min-width: 48rem) {
      header.main-header .main-header__wrapper {
        padding: 1.5rem 2rem; } }
    @media screen and (min-width: 89rem) {
      header.main-header .main-header__wrapper {
        padding: 2rem 0; } }
    header.main-header .main-header__wrapper .main-header__wrapper-inner {
      max-width: 85rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner > :first-child {
        margin-top: 0; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner > :last-child {
        margin-bottom: 0; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo {
        max-width: 15rem;
        min-width: 15rem;
        margin-right: 1.875rem; }
        @media screen and (min-width: 98.75em) {
          header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo {
            margin-left: -5.5rem; } }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo-sticky {
        display: none; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .global-mobile-toggle {
        display: none; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper {
        flex: 1 0 auto;
        display: flex;
        align-items: center; }
        header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav {
          flex: 1 0 auto; }
          header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list {
            justify-content: center; }
  header.main-header .global-search {
    order: 1;
    height: 0;
    padding: 0;
    overflow: hidden;
    transition: all 0.2s ease-out; }
    header.main-header .global-search.global-search--active {
      height: auto;
      padding: 2rem; }

@media screen and (max-width: 74.9375em) {
  header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo {
    min-width: 10.125rem; }
  header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper {
    flex: 0 1 auto;
    flex-flow: row wrap; }
    header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav {
      flex: 0 1 100%;
      order: 2; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list {
        justify-content: flex-end; }
        header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list li:last-child a {
          padding-right: 0; }
        header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list li:last-child .global-main-nav__dropdown {
          right: 0; }
    header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-utility-nav {
      flex: 0 1 100%;
      order: 1;
      margin-bottom: 1.25rem; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-utility-nav ul {
        justify-content: flex-end; } }

@media screen and (max-width: 63.9375em) {
  header.main-header .main-header__wrapper {
    padding-left: 0;
    padding-right: 0; }
    header.main-header .main-header__wrapper .main-header__wrapper-inner {
      flex-flow: row wrap; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo,
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo-sticky {
        margin-left: 1.875rem; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .global-mobile-toggle {
        display: block;
        margin-right: 1.875rem; }
      header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper {
        display: none;
        order: 3;
        flex: 0 1 100%; }
        header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav {
          order: 1; }
          header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list {
            flex-flow: row wrap; }
            header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item {
              flex: 0 1 100%; }
              header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item .global-main-nav__dropdown {
                opacity: 1;
                visibility: visible;
                position: static;
                padding-top: 0;
                display: none; }
                header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item .global-main-nav__dropdown ul {
                  width: auto; }
        header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-utility-nav {
          order: 2;
          margin-bottom: -1.875rem; } }

@media screen and (max-width: 40em) {
  header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo {
    min-width: 0;
    max-width: 12.5rem; } }

@media screen and (max-width: 23.75em) {
  header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__logo {
    min-width: 0;
    max-width: 10.625rem; }
  header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-main-nav ul.global-main-nav__list li.global-main-nav__parent-item a.global-main-nav__parent-link {
    font-size: 1.5rem; } }

.sticky-wrapper.stuck {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%; }
  .sticky-wrapper.stuck.scaled .main-header__wrapper {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
    @media screen and (max-width: 23.75em) {
      .sticky-wrapper.stuck.scaled .main-header__wrapper {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem; } }
    .sticky-wrapper.stuck.scaled .main-header__wrapper .main-header__logo {
      display: none; }
    .sticky-wrapper.stuck.scaled .main-header__wrapper .main-header__logo-sticky {
      display: block; }
    @media screen and (max-width: 74.9375em) {
      .sticky-wrapper.stuck.scaled .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-utility-nav {
        margin-bottom: 0.3125rem; } }
    @media screen and (max-width: 63.9375em) {
      .sticky-wrapper.stuck.scaled .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-utility-nav {
        margin-bottom: -1.875rem; } }
    @media screen and (max-width: 23.75em) {
      .sticky-wrapper.stuck.scaled .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper .global-mobile-toggle button {
        padding: 0; } }

.global-mobile-overlay {
  display: none; }

@media screen and (max-width: 63.9375em) {
  body.mobile-nav-open {
    overflow-y: hidden;
    height: 100%; }
    body.mobile-nav-open .sticky-wrapper {
      overflow-y: scroll;
      height: 100%;
      position: absolute;
      z-index: 999; }
      body.mobile-nav-open .sticky-wrapper.stuck {
        position: fixed; }
    body.mobile-nav-open .global-mobile-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: -100%;
      right: 0;
      z-index: 99;
      background: rgba(1, 33, 105, 0.7);
      display: block; } }

@media screen and (min-width: 64em) {
  header.main-header .main-header__wrapper .main-header__wrapper-inner .main-header__links-wrapper.flex-slidetoggle-fix {
    display: flex !important; } }

.main-footer {
  background: #012169;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.375rem; }
  .main-footer abbr {
    color: #fff; }
  .main-footer a:link, .main-footer a:visited {
    color: #fff; }
  .main-footer a.link--fancy:before, .main-footer .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .main-footer a:before {
    border-color: #fff;
    background-image: url("../images/fancy-link-angle-white.svg"); }
  .main-footer a.link--fancy:hover, .main-footer .link-collection.link-collection--fancy a:hover, .link-collection.link-collection--fancy .main-footer a:hover, .main-footer a.link--fancy:active, .main-footer .link-collection.link-collection--fancy a:active, .link-collection.link-collection--fancy .main-footer a:active, .main-footer a.link--fancy:focus, .main-footer .link-collection.link-collection--fancy a:focus, .link-collection.link-collection--fancy .main-footer a:focus {
    color: #F2A900; }
    .main-footer a.link--fancy:hover:before, .main-footer .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .main-footer a:hover:before, .main-footer a.link--fancy:active:before, .main-footer .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .main-footer a:active:before, .main-footer a.link--fancy:focus:before, .main-footer .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .main-footer a:focus:before {
      border-color: #F2A900; }
  .main-footer a.link--fancy.link--fancy-filled:before, .main-footer .link-collection.link-collection--fancy a.link--fancy-filled:before, .link-collection.link-collection--fancy .main-footer a.link--fancy-filled:before {
    background-color: #007DBA; }
  .main-footer a.link--fancy.link--fancy-filled:hover:before, .main-footer .link-collection.link-collection--fancy a.link--fancy-filled:hover:before, .link-collection.link-collection--fancy .main-footer a.link--fancy-filled:hover:before, .main-footer a.link--fancy.link--fancy-filled:active:before, .main-footer .link-collection.link-collection--fancy a.link--fancy-filled:active:before, .link-collection.link-collection--fancy .main-footer a.link--fancy-filled:active:before, .main-footer a.link--fancy.link--fancy-filled:focus:before, .main-footer .link-collection.link-collection--fancy a.link--fancy-filled:focus:before, .link-collection.link-collection--fancy .main-footer a.link--fancy-filled:focus:before {
    background-color: #41B6E6; }
  .main-footer blockquote {
    border-color: #fff; }
  .main-footer hr {
    border-color: rgba(255, 255, 255, 0.2); }
  .main-footer table {
    color: #101820; }
    .main-footer table caption {
      color: #fff; }
  .main-footer button,
  .main-footer .button,
  .main-footer .pagination ul li a,
  .pagination ul li .main-footer a {
    background-color: #007DBA; }
    .main-footer button:after,
    .main-footer .button:after,
    .main-footer .pagination ul li a:after,
    .pagination ul li .main-footer a:after {
      background-color: #41B6E6; }
    .main-footer button:hover, .main-footer button:active,
    .main-footer .button:hover,
    .main-footer .pagination ul li a:hover,
    .pagination ul li .main-footer a:hover,
    .main-footer .button:active,
    .main-footer .pagination ul li a:active,
    .pagination ul li .main-footer a:active {
      color: #101820; }
  .main-footer .social-media a {
    color: #41B6E6; }
    .main-footer .social-media a:hover, .main-footer .social-media a:active, .main-footer .social-media a:focus {
      color: #fff; }
  .main-footer .breadcrumbs {
    color: #F2A900; }
  .main-footer ul {
    margin: 0 0 0 0;
    list-style: none;
    padding: 0 0 0 0; }
    .main-footer ul > li {
      text-indent: 0;
      margin-left: 0;
      margin-bottom: 0; }
      .main-footer ul > li:before {
        display: none; }
    .main-footer ul li {
      font-size: inherit;
      line-height: inherit; }
  
  .main-footer .button,
  .main-footer .pagination ul li a,
  .pagination ul li .main-footer a {
    font-size: inherit; }
  .main-footer .main-footer__ribbon {
    background: rgba(0, 0, 0, 0.2); }
  .main-footer .main-footer__primary,
  .main-footer .main-footer__ribbon {
    padding: 1rem 1rem; }
    @media screen and (min-width: 35rem) {
      .main-footer .main-footer__primary,
      .main-footer .main-footer__ribbon {
        padding: 1.5rem 2rem; } }
    @media screen and (min-width: 48rem) {
      .main-footer .main-footer__primary,
      .main-footer .main-footer__ribbon {
        padding: 1.5rem 2rem; } }
    @media screen and (min-width: 89rem) {
      .main-footer .main-footer__primary,
      .main-footer .main-footer__ribbon {
        padding: 2rem 0; } }
    .main-footer .main-footer__primary .main-footer__primary-wrapper,
    .main-footer .main-footer__primary .main-footer__ribbon-wrapper,
    .main-footer .main-footer__ribbon .main-footer__primary-wrapper,
    .main-footer .main-footer__ribbon .main-footer__ribbon-wrapper {
      max-width: 85rem;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap; }
      .main-footer .main-footer__primary .main-footer__primary-wrapper > :first-child,
      .main-footer .main-footer__primary .main-footer__ribbon-wrapper > :first-child,
      .main-footer .main-footer__ribbon .main-footer__primary-wrapper > :first-child,
      .main-footer .main-footer__ribbon .main-footer__ribbon-wrapper > :first-child {
        margin-top: 0; }
      .main-footer .main-footer__primary .main-footer__primary-wrapper > :last-child,
      .main-footer .main-footer__primary .main-footer__ribbon-wrapper > :last-child,
      .main-footer .main-footer__ribbon .main-footer__primary-wrapper > :last-child,
      .main-footer .main-footer__ribbon .main-footer__ribbon-wrapper > :last-child {
        margin-bottom: 0; }
  .main-footer .main-footer__primary {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    overflow: hidden; }
    .main-footer .main-footer__primary .main-footer__primary-wrapper {
      justify-content: space-between; }
      @media screen and (max-width: 63.9375em) {
        .main-footer .main-footer__primary .main-footer__primary-wrapper {
          flex-flow: row wrap; } }
      .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__logo {
        flex: 0 1 35%;
        position: relative; }
        .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__logo:after {
          display: inline-block;
          content: '';
          position: absolute;
          background: url("../images/triangle-single-down.svg") no-repeat top left;
          width: 49.0625rem;
          height: 24.75rem;
          top: -2.5rem;
          right: -30%;
          mix-blend-mode: hard-light;
          z-index: 0; }
          @media screen and (max-width: 74.9375em) {
            .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__logo:after {
              right: auto;
              left: -10rem; } }
        @media screen and (max-width: 74.9375em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__logo {
            flex: 0 1 22%; } }
        @media screen and (max-width: 63.9375em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__logo {
            flex: 0 1 30%; } }
        @media screen and (max-width: 40em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__logo {
            flex: 0 1 100%;
            margin-bottom: 1.875rem; } }
        .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__logo .logo {
          display: inline-block;
          max-width: 15.625rem;
          position: relative;
          z-index: 2; }
      .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection,
      .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact {
        flex: 0 1 19%;
        position: relative;
        z-index: 2; }
        @media screen and (max-width: 74.9375em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection,
          .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact {
            flex: 0 1 22%; } }
      .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection {
        border-top: rgba(255, 255, 255, 0.25) 1px solid; }
        @media screen and (max-width: 63.9375em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection {
            flex: 0 1 30%; } }
        @media screen and (max-width: 40em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection {
            flex: 0 1 47%; } }
        @media screen and (max-width: 23.75em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection {
            flex: 0 1 100%; } }
        .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection h1, .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection h2, .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection h3, .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection h4, .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection h5, .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection h6 {
          color: #F2A900;
          font-size: 1.375rem;
          line-height: 2rem;
          margin: 0.625rem 0; }
        .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection ul li {
          margin-bottom: 0.625rem; }
          .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection ul li:last-child {
            margin-bottom: 0; }
          @media screen and (max-width: 40em) {
            .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection ul li {
              margin-bottom: 1.25rem; } }
        .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection .vcard {
          margin-bottom: 1.25rem; }
          .main-footer .main-footer__primary .main-footer__primary-wrapper .link-collection .vcard span.title {
            font-weight: bold; }
      @media screen and (max-width: 63.9375em) {
        .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact {
          flex: 0 1 100%;
          display: flex;
          align-items: center; } }
      @media screen and (max-width: 23.75em) {
        .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact {
          display: block; } }
      .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact .button, .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact .pagination ul li a, .pagination ul li .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact a {
        margin-bottom: 1.25rem; }
        @media screen and (max-width: 63.9375em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact .button, .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact .pagination ul li a, .pagination ul li .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact a {
            margin-bottom: 0;
            margin-right: 1.25rem; } }
        @media screen and (max-width: 23.75em) {
          .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact .button, .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact .pagination ul li a, .pagination ul li .main-footer .main-footer__primary .main-footer__primary-wrapper .main-footer__support-contact a {
            margin-bottom: 1.25rem; } }
  .main-footer .main-footer__ribbon-wrapper {
    justify-content: flex-start; }
    @media screen and (max-width: 40em) {
      .main-footer .main-footer__ribbon-wrapper {
        padding-top: 1.25rem; } }
    @media screen and (max-width: 40em) {
      .main-footer .main-footer__ribbon-wrapper .link-collection {
        flex: 0 1 100%; } }
    .main-footer .main-footer__ribbon-wrapper .link-collection ul li {
      display: inline;
      margin-left: 1.875rem; }
      @media screen and (max-width: 40em) {
        .main-footer .main-footer__ribbon-wrapper .link-collection ul li {
          display: block;
          margin-left: 0;
          margin-bottom: 1.25rem; }
          .main-footer .main-footer__ribbon-wrapper .link-collection ul li:first-child {
            margin-top: 1.25rem; } }

.page-header {
  padding: 1rem 1rem;
  background: #012169;
  color: #fff; }
  @media screen and (min-width: 35rem) {
    .page-header {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 48rem) {
    .page-header {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 89rem) {
    .page-header {
      padding: 2rem 0; } }
  .page-header abbr {
    color: #fff; }
  .page-header a:link, .page-header a:visited {
    color: #fff; }
  .page-header a.link--fancy:before, .page-header .link-collection.link-collection--fancy a:before, .link-collection.link-collection--fancy .page-header a:before {
    border-color: #fff;
    background-image: url("../images/fancy-link-angle-white.svg"); }
  .page-header a.link--fancy:hover, .page-header .link-collection.link-collection--fancy a:hover, .link-collection.link-collection--fancy .page-header a:hover, .page-header a.link--fancy:active, .page-header .link-collection.link-collection--fancy a:active, .link-collection.link-collection--fancy .page-header a:active, .page-header a.link--fancy:focus, .page-header .link-collection.link-collection--fancy a:focus, .link-collection.link-collection--fancy .page-header a:focus {
    color: #F2A900; }
    .page-header a.link--fancy:hover:before, .page-header .link-collection.link-collection--fancy a:hover:before, .link-collection.link-collection--fancy .page-header a:hover:before, .page-header a.link--fancy:active:before, .page-header .link-collection.link-collection--fancy a:active:before, .link-collection.link-collection--fancy .page-header a:active:before, .page-header a.link--fancy:focus:before, .page-header .link-collection.link-collection--fancy a:focus:before, .link-collection.link-collection--fancy .page-header a:focus:before {
      border-color: #F2A900; }
  .page-header a.link--fancy.link--fancy-filled:before, .page-header .link-collection.link-collection--fancy a.link--fancy-filled:before, .link-collection.link-collection--fancy .page-header a.link--fancy-filled:before {
    background-color: #007DBA; }
  .page-header a.link--fancy.link--fancy-filled:hover:before, .page-header .link-collection.link-collection--fancy a.link--fancy-filled:hover:before, .link-collection.link-collection--fancy .page-header a.link--fancy-filled:hover:before, .page-header a.link--fancy.link--fancy-filled:active:before, .page-header .link-collection.link-collection--fancy a.link--fancy-filled:active:before, .link-collection.link-collection--fancy .page-header a.link--fancy-filled:active:before, .page-header a.link--fancy.link--fancy-filled:focus:before, .page-header .link-collection.link-collection--fancy a.link--fancy-filled:focus:before, .link-collection.link-collection--fancy .page-header a.link--fancy-filled:focus:before {
    background-color: #41B6E6; }
  .page-header blockquote {
    border-color: #fff; }
  .page-header hr {
    border-color: rgba(255, 255, 255, 0.2); }
  .page-header table {
    color: #101820; }
    .page-header table caption {
      color: #fff; }
  .page-header button,
  .page-header .button,
  .page-header .pagination ul li a,
  .pagination ul li .page-header a {
    background-color: #007DBA; }
    .page-header button:after,
    .page-header .button:after,
    .page-header .pagination ul li a:after,
    .pagination ul li .page-header a:after {
      background-color: #41B6E6; }
    .page-header button:hover, .page-header button:active,
    .page-header .button:hover,
    .page-header .pagination ul li a:hover,
    .pagination ul li .page-header a:hover,
    .page-header .button:active,
    .page-header .pagination ul li a:active,
    .pagination ul li .page-header a:active {
      color: #101820; }
  .page-header .social-media a {
    color: #41B6E6; }
    .page-header .social-media a:hover, .page-header .social-media a:active, .page-header .social-media a:focus {
      color: #fff; }
  .page-header .breadcrumbs {
    color: #F2A900; }
  .page-header .page-header__wrapper {
    max-width: 85rem;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between; }
    .page-header .page-header__wrapper > :first-child {
      margin-top: 0; }
    .page-header .page-header__wrapper > :last-child {
      margin-bottom: 0; }
    .page-header .page-header__wrapper .page-header__body {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center; }
    .page-header .page-header__wrapper .page-header__media {
      flex: 1 0 50%;
      margin-left: 2.5rem; }
  .page-header.page-header--fill-media {
    position: relative;
    overflow: hidden; }
    .page-header.page-header--fill-media .page-header__wrapper .page-header__body {
      min-height: 25rem;
      flex: 0 1 50%;
      padding-right: 2.5rem; }
    .page-header.page-header--fill-media .page-header__wrapper .page-header__media {
      width: 100%;
      max-width: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: '';
      display: block;
      margin: 0;
      z-index: 0;
      max-width: 50%;
      left: auto;
      right: 0;
      overflow: hidden; }
      .page-header.page-header--fill-media .page-header__wrapper .page-header__media > img {
        position: absolute;
        max-width: none;
        max-height: none;
        width: auto;
        min-width: 100%;
        height: auto;
        min-height: 100%;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        top: 50%;
        transform: translate(-50%, -50%); }

.teaser-collection {
  padding: 1rem 1rem; }
  @media screen and (min-width: 35rem) {
    .teaser-collection {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 48rem) {
    .teaser-collection {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 89rem) {
    .teaser-collection {
      padding: 2rem 0; } }
  .teaser-collection .collection__headline,
  .teaser-collection .collection__items {
    max-width: 85rem;
    margin: 0 auto; }
    .teaser-collection .collection__headline > :first-child,
    .teaser-collection .collection__items > :first-child {
      margin-top: 0; }
    .teaser-collection .collection__headline > :last-child,
    .teaser-collection .collection__items > :last-child {
      margin-bottom: 0; }
  .teaser-collection .collection__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
    .teaser-collection .collection__items .collection__item {
      flex: 0 1 22%;
      margin-bottom: 2.5rem; }
      @media screen and (max-width: 63.9375em) {
        .teaser-collection .collection__items .collection__item {
          flex: 0 1 46%; } }
      @media screen and (max-width: 39.9375em) {
        .teaser-collection .collection__items .collection__item {
          flex: 0 1 100%;
          margin-bottom: 1.66667rem; } }
      .teaser-collection .collection__items .collection__item .teaser__headline .headline-group {
        font: normal normal 1.875rem "Spectral", serif;
        line-height: 1.2;
        text-transform: none; }
        @media screen and (max-width: 23.75em) {
          .teaser-collection .collection__items .collection__item .teaser__headline .headline-group {
            font-size: 1.625rem; } }
  .teaser-collection.collection--three-column .collection__items .collection__item {
    flex: 0 1 30%; }
    @media screen and (max-width: 39.9375em) {
      .teaser-collection.collection--three-column .collection__items .collection__item {
        flex: 0 1 100%; } }
    .teaser-collection.collection--three-column .collection__items .collection__item .teaser__headline .headline-group {
      font: normal normal 2.125rem "Spectral", serif;
      line-height: 1.2;
      text-transform: none; }
      @media screen and (max-width: 23.75em) {
        .teaser-collection.collection--three-column .collection__items .collection__item .teaser__headline .headline-group {
          font-size: 1.875rem; } }
  .teaser-collection.collection--two-column .collection__items .collection__item {
    flex: 0 1 46%; }
    @media screen and (max-width: 39.9375em) {
      .teaser-collection.collection--two-column .collection__items .collection__item {
        flex: 0 1 100%; } }
    .teaser-collection.collection--two-column .collection__items .collection__item .teaser__headline .headline-group {
      font: normal normal 2.875rem "Spectral", serif;
      line-height: 1.2;
      text-transform: none; }
      @media screen and (max-width: 40em) {
        .teaser-collection.collection--two-column .collection__items .collection__item .teaser__headline .headline-group {
          font-size: 2.5rem; } }
      @media screen and (max-width: 23.75em) {
        .teaser-collection.collection--two-column .collection__items .collection__item .teaser__headline .headline-group {
          font-size: 2.125rem; } }

.fact-collection {
  padding: 1rem 1rem; }
  @media screen and (min-width: 35rem) {
    .fact-collection {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 48rem) {
    .fact-collection {
      padding: 1.5rem 2rem; } }
  @media screen and (min-width: 89rem) {
    .fact-collection {
      padding: 2rem 0; } }
  .fact-collection .fact-collection__headline,
  .fact-collection .fact-collection__facts {
    max-width: 85rem;
    margin: 0 auto; }
    .fact-collection .fact-collection__headline > :first-child,
    .fact-collection .fact-collection__facts > :first-child {
      margin-top: 0; }
    .fact-collection .fact-collection__headline > :last-child,
    .fact-collection .fact-collection__facts > :last-child {
      margin-bottom: 0; }
  .fact-collection .fact-collection__facts {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
    .fact-collection .fact-collection__facts .fact {
      flex: 0 1 22%; }
      @media screen and (max-width: 63.9375em) {
        .fact-collection .fact-collection__facts .fact {
          flex: 0 1 46%; } }
      @media screen and (max-width: 39.9375em) {
        .fact-collection .fact-collection__facts .fact {
          flex: 0 1 100%;
          margin-bottom: 1.66667rem; } }
