.story-header {
    .headline-group {
        // @extend .headline-group--simple-superhead;
        .superhead {
            margin-bottom: 0;
            @include breakpoint($fix-xsmall down) {
                margin-bottom: rem-calc(30);
            }
        }
        .head {
            @include fontstyle(h2);
        }
        .subhead {
            font-size: rem-calc(28);
            margin-top: 0;
            margin-bottom: rem-calc(30);
            @include breakpoint($fix-xsmall down) {
                margin-top: rem-calc(20);
            }
        }
    }
}