.search-bar {
    form {
        display: flex;
        input[type="text"] {
            margin-bottom: 0;
            margin-top: 0;
            height: auto;
        }
        button {
            @include no-triangle-button;
            svg {
                color: #fff;
            }
        }
    }
}