.slab {
    @include rr-slab;
}

.slab__wrapper {
    @include rr-slab-wrapper;
}


// .slab {
//     &.slab--edge-to-edge {
//         .slab__wrapper {
//             max-width: none;
//         }
//     }
// }

// color/background options
.slab {
    &.slab--dark {
        background: $color-primary;
        @include ondark;
    }

    &.slab--bright {
        background: $color-quaternary;
        @include onbright;
    }
}


// $skins: transparent;

// @each $name,$color in $colors {
//     .slab--#{$name} {
//         background-color: $color;
//         contrast: contrast($color,$black);
//         @if (contrast($color,color(wildcat-black)) < 4.5) {
//             @include usescheme($text-colors-dark);
//         }
//     }
//     $skins: append($skins, slab--#{$name});
// }


// @include rr-slab-adjacency($style-list: $skins);

