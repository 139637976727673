$image-url-path: "../images/";
$arrow-right: '\279e';

// root rhythm & grid
$rr-maxwidth: 85rem;
$collection-4up: 22%;
$collection-3up: 30%;
$collection-2up: 46%;


// spacing helpers
$max-width: $rr-maxwidth;
$max-width-thin: rem-calc(740);
$margin-bottom: rem-calc(40);
$margin-bottom-large: rem-calc(60);
$gutter: rem-calc(40);


// animation settings
$transition-time: 0.2s;
$transition-easing: ease-out;


// additional breakpoints to add to the existing foundation ones
$graceful-touch: $max-width + rem-calc(80);
$fix-xsmall: 380px;
$fix-small: 640px;


// dotted lines
$border-dot-size: 4.5;
$border-dot-spacing: 3.5;
$border-dot-color: $color-quaternary;