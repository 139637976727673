header.main-header {
	box-shadow: 0 0 10px rgba(#000, 0.5);
	position: relative;
	display: flex;
	flex-flow: row wrap;
	z-index: 2;
	background: #fff;

	a,
	button {
		@include link-strokeless;
		color: $color-primary;
		&:link,
		&:visited {
			color: $color-primary;
		}
		&:hover,
		&:active,
		&:focus {
			color: $color-tertiary;
		}
		
	}

	.main-header__wrapper,
	.global-search {
		flex: 1 0 100%;
	}

	.main-header__wrapper {
		@include rr-slab;
		order: 2;
		transition: all $transition-time $transition-easing;
		.main-header__wrapper-inner {
			@include rr-slab-wrapper;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.main-header__logo {
				max-width: rem-calc(240);
				min-width: rem-calc(240);
				margin-right: rem-calc(30);
				// let the shield hang outside the max-width
				// and align the "text" of the logo with the rest of the content
				// when the screen is wide enough to allow for it
				@include breakpoint(($max-width + rem-calc(220)) up) {
					margin-left: rem-calc(-88);
				}
			}
			.main-header__logo-sticky {
				display: none;
			}

			.global-mobile-toggle {
				display: none;
			}

			.main-header__links-wrapper {
				flex: 1 0 auto;
				display: flex;
				align-items: center;
				.global-main-nav {
					flex: 1 0 auto;
					ul.global-main-nav__list {
						justify-content: center;
					}
				}
				.global-utility-nav {
					// 
				}
			}
		}
	}

	.global-search {
		order: 1;
		height: 0;
		padding: 0;
		overflow: hidden;
		transition: all $transition-time $transition-easing;
		&.global-search--active {
			height: auto;
			padding: 2rem;
		}
	}
}



// middle screen sizes/tablets ------------------------
// this is not my preferred way of handling breakpoints
// (breaking them out of context like this)
// but this is a complex header and it was getting
// too messy to keep them inline.
// sorry.
@include breakpoint(large down) {
	header.main-header {
		.main-header__wrapper {
			.main-header__wrapper-inner {
				.main-header__logo {
					min-width: rem-calc(162);
				}
				.main-header__links-wrapper {
					flex: 0 1 auto;
					flex-flow: row wrap;
					.global-main-nav {
						flex: 0 1 100%;
						order: 2;
						ul.global-main-nav__list {
							justify-content: flex-end;
							li {
								&:last-child {
									a {
										padding-right: 0;
									}
									.global-main-nav__dropdown {
										right: 0;
									}
								}
							}
						}
					}
					.global-utility-nav {
						flex: 0 1 100%;
						order: 1;
						margin-bottom: rem-calc(20);
						ul {
							justify-content: flex-end;
						}
					}
				}
			}
		}
	}
}



// medium / where the mobile/collapsed menu engages ------------------
@include breakpoint(medium down) {
	header.main-header {
		.main-header__wrapper {
			padding-left: 0;
			padding-right: 0;
			.main-header__wrapper-inner {
				flex-flow: row wrap;
				.main-header__logo,
				.main-header__logo-sticky {
					margin-left: rem-calc(30);
				}
				.global-mobile-toggle {
					display: block;
					margin-right: rem-calc(30);
				}
				.main-header__links-wrapper {
					display: none;
					order: 3;
					flex: 0 1 100%;
					.global-main-nav {
						order: 1;
						ul.global-main-nav__list {
							flex-flow: row wrap;
							li.global-main-nav__parent-item {
								flex: 0 1 100%;
								.global-main-nav__dropdown {
									opacity: 1;
									visibility: visible;
									position: static;
									padding-top: 0;
									display: none;
									ul {
										width: auto;
									}
								}
							}
						}
					}
					.global-utility-nav {
						order: 2;
						margin-bottom: rem-calc(-30);
					}
				}
			}
		}
	}
}

// small screen sizes/phones ------------------
@include breakpoint($fix-small down) {
	header.main-header {
		.main-header__wrapper {
			.main-header__wrapper-inner {
				.main-header__logo {
					min-width: 0;
					max-width: rem-calc(200);
				}
			}
		}
	}
}

// smallest screen sizes/phones ------------------
@include breakpoint($fix-xsmall down) {
	header.main-header {
		.main-header__wrapper {
			.main-header__wrapper-inner {
				.main-header__logo {
					min-width: 0;
					max-width: rem-calc(170);
				}
				.main-header__links-wrapper {
					.global-main-nav {
						ul.global-main-nav__list {
							li.global-main-nav__parent-item {
								a.global-main-nav__parent-link {
									font-size: rem-calc(24);
								}
							}
						}
					}
				}
			}
		}
	}
}


// states
// ----------------------------
// prob wanna move these to a separate file later :)
.sticky-wrapper.stuck {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999999;
	width: 100%;
	&.scaled {
		.main-header__wrapper {
			padding-top: rem-calc(20);
			padding-bottom: rem-calc(20);
			@include breakpoint($fix-xsmall down) {
				padding-top: rem-calc(6);
				padding-bottom: rem-calc(6);
			}
			.main-header__logo {
				display: none;
			}
			.main-header__logo-sticky {
				display: block;
			}
			.main-header__wrapper-inner {
				.main-header__links-wrapper {
					.global-utility-nav {
						@include breakpoint(large down) {
							margin-bottom: rem-calc(5);
						}
						@include breakpoint(medium down) {
							margin-bottom: rem-calc(-30);
						}
					}
					.global-mobile-toggle {
						button {
							@include breakpoint($fix-xsmall down) {
								padding: 0;
							}
						}
					}
				}
			}
		}
	}
}

.global-mobile-overlay {
	display: none;
}

body.mobile-nav-open {
	@include breakpoint(medium down) {
		overflow-y: hidden;
		height: 100%;
		.sticky-wrapper {
			overflow-y: scroll;
			height: 100%;
			position: absolute;
			z-index: 999;
			&.stuck {
				position: fixed;
			}
		}
		.global-mobile-overlay {
			position: absolute;
			top: 0;
			left: 0;
			bottom: -100%;  // just go with it
			right: 0;
			z-index: 99;
			background: rgba($color-primary, 0.7);
			display: block;
		}
	}
}

// for users who resize the window while toggling nav
// mostly testers and internal teams...
header.main-header {
	.main-header__wrapper {
		.main-header__wrapper-inner {
			.main-header__links-wrapper {
				&.flex-slidetoggle-fix {
					@include breakpoint(large up) {
						display: flex !important;
					}
				}
			}
		}
	}
}