hr {
    border-color: rgba(#000, 0.3);
    border-bottom-width: rem-calc(2);
    margin-top: 0;
    margin-bottom: $margin-bottom;
    max-width: none;

    &.hr--dots {
        @include border-dotted($sides: 'bottom');
    }
}