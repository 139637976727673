.global-main-nav {
	ul.global-main-nav__list {
		@include strip-list;
		display: flex;
		li.global-main-nav__parent-item {
			// margin-right: rem-calc(20);
			position: relative;
			// z-index: 1;
			&:last-child {
				margin-right: 0;
			}
			a.global-main-nav__parent-link {
				@include link-strokeless;
				font-family: $font-tertiary;
				font-size: rem-calc(28);
				padding: rem-calc(20 40 20 0);
			}
			button.global-main-nav__parent-link {
				@include strip-button;
				font-family: $font-tertiary;
				font-size: rem-calc(28);
			}
			button.global-main-nav__mobile-dropdown-toggle {
				display: none;
				// @include breakpoint(medium down) {
				// 	display: inline-block;
				// 	@include link-style-button;
				// }
			}
		}
	}

	.global-main-nav__dropdown {
		// display: none;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		padding-top: rem-calc(10);
		transition: all $transition-time $transition-easing;
		ul {
			@include strip-list;
			background: $color-primary;
			@include top-stroke($color-octonary);
			padding: rem-calc(30);
			width: rem-calc(250);
			li {
				font-weight: bold;
				font-size: rem-calc(14);
				margin-bottom: rem-calc(22);
				&:last-child {
					margin-bottom: 0;
				}
				a {
					@include link-strokeless;
					color: #fff;
					&:hover,
					&:active,
					&:focus {
						color: $color-quaternary;
					}
				}
			}
		}
	}
}

// active states
.global-main-nav {
	ul.global-main-nav__list {
		li.global-main-nav__parent-item.toggleDropdown--active {
			a.global-main-nav__parent-link {
				color: $color-tertiary;
			}
			.global-main-nav__dropdown {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

// collabsible list view (tablet-ish and down)
@include breakpoint(medium down) {
	.global-main-nav {
		ul.global-main-nav__list {
			li.global-main-nav__parent-item {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				border-bottom: $color-shade-lighter 1px solid;
				a.global-main-nav__parent-link {
					padding: rem-calc(10 30);
					// flex: 1 0 auto;
				}
				button.global-main-nav__mobile-dropdown-toggle {
					@include link-style-button;
					// @include strip-button;
					display: inline-block;
					padding: 0 rem-calc(20);
					// background: $color-shade-lightest;
					&:hover {
						// background: $color-shade-light;
					}
					&:focus {
						// outline: 0;
						// background: $color-octonary;
						svg {
							// color: #fff;
						}
					}
					svg {
						transform: rotate(90deg);
						transition: transform $transition-time $transition-easing;
					}
					&.active {
						background: $color-octonary;
						svg {
							transform: rotate(-90deg);
							color: #fff;
						}
					}
				}
				.global-main-nav__dropdown {
					flex: 0 1 100%;
					// opacity: 1;
					// visibility: visible;
				}
			}
		}
	}
}