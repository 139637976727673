
.headline-group {

	.superhead,
	.subhead {
		display: block;
		// color: $color-tertiary;
		font-family: $font-tertiary;
		font-size: rem-calc(22);
		line-height: rem-calc(32);
	}
	.superhead {
		text-transform: uppercase;
		margin-bottom: rem-calc(10);
	}
	.subhead {
		margin-top: rem-calc(10);
	}


	&.headline-group--nonsemantic {
		@for $i from 1 through 6 {
			&.headline-group--nonsemantic-level#{$i} {
				@include fontstyle("h#{$i}");
			}
		}
	}

	&.headline-group--simple-superhead {
		.superhead {
			font-family: $font-primary;
			font-size: rem-calc(16);
			line-height: rem-calc(26);
			text-transform: none;
		}
	}
}
