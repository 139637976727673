@mixin ornament-above {
	margin-top: $ornament-size / 2;
	padding-top: $ornament-size / 2;
	position: relative;

	.ornament {
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%) translateY(-50%);
	}
}

@mixin ornament-below {
	margin-bottom: $ornament-size / 2;
	padding-bottom: $ornament-size / 2;
	position: relative;

	.ornament {
		bottom: 0;
		left: 50%;
		position: absolute;
		transform: translateX(-50%) translateY(50%);
	}
}


// Background image of a horizontal line, made up of dots.
// Arguments:
//		$size[pixel value] default: set in variables file
//			Size of a single dot in pixels
//
//		$spacing[float]	default: set in variables file
//			Size ratio of space between dots to dot size. Larger value means bigger space between dots.
//
//		$color[color hex value] default: set in variables file
//			Color of dots
@mixin line-dotted($size:$border-dot-size, $spacing:$border-dot-spacing, $color:$border-dot-color) {

	$radius: $size / 2;
	$space-width: $spacing * $size;

	$image-horizontal: "data:image/svg+xml;utf8,<svg width='#{$size + $space-width}' height='#{$size}' viewBox='0 0 #{$size + $space-width} #{$size}' xmlns='http://www.w3.org/2000/svg'><ellipse fill='#{$color}' cx='#{$radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/></svg>";

	background-image: url($image-horizontal);
	background-repeat: repeat-x;
	background-position: center center;
	background-size: #{$size + $space-width}px auto;

}

// Similar to line-dotted, this mixin puts a dotted border around an element on any or all sides.
// Takes same arguments as line-dotted, and also:
// 	$sides[up to 4 strings] default: 'top' 'right' 'bottom' 'left'
//		Border is applied only to the sides listed in this argument.

$dotted-border-sides-default: 'top' 'right' 'bottom' 'left';

@mixin border-dotted($size:$border-dot-size, $spacing:$border-dot-spacing, $color:$border-dot-color, $sides:$dotted-border-sides-default) {
	$radius: $size / 2;
	$space-width: $spacing * $size;

	$image-size: $size * 3 + $space-width * 2;

	$image-border: "data:image/svg+xml;utf8,<svg width='#{$image-size}' height='#{$image-size}' viewBox='0 0 #{$image-size} #{$image-size}' xmlns='http://www.w3.org/2000/svg'><g fill='#{$color}'><ellipse cx='#{$radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$size + $space-width + $radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{($size * 2) + $space-width * 2 + $radius}' cy='#{$radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$radius}' cy='#{$size + $space-width + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{($size * 2) + $space-width * 2 + $radius}' cy='#{$size + $space-width + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$radius}' cy='#{($size * 2) + ($spacing * $size * 2) + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{$size + $space-width + $radius}' cy='#{($size * 2) + $space-width * 2 + $radius}' rx='#{$radius}' ry='#{$radius}'/><ellipse cx='#{($size * 2) + $space-width * 2 + $radius}' cy='#{($size * 2) + $space-width * 2 + $radius}' rx='#{$radius}' ry='#{$radius}'/></g></svg>";

	border-width: 0;
	border-style: solid;
	border-image: url($image-border) 33% round;

	@each $side in $sides {
		border-#{$side}-width: #{$radius + $space-width}px;
	}

}

@mixin top-stroke($color: $color-octonary) {
	border-top: rem-calc(4) $color solid;
}