// Stretch an element (usually a <figure>) and its child image to fill the
// element's parent container
//
// Options:
//		$beneath[true|false] default: false
//				If true, places the stretched element underneath its siblings. Useful
//				for image backgrounds.
//
//		$fill['width'|'height'|'both']
//			'width'		:	Image stretches to fill parent width, height scales proportionally
//			'height'	:	Image stretches to fill parent height, width scales proportionally
//			'both'		:	Image stretches to fill the shorter of width or height, cropping the
//									longer dimension
//
//		$pin['left'|'right'|'center' 'top'|'bottom'|'center'] default: 'top' 'center'
//			When the image must be cropped to fit, this determines which vertical and
//			horizontal side will remain unchanged. If 'center', then both sides are cropped
//			equally.


@mixin pseudo-cover ($beneath:false, $fill: 'both', $pin:'center' 'top') {
	width: 100%;
	max-width: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	content: '';
	display: block;

	margin: 0;

	@if $beneath {
		z-index: 0;
	}

	> img {
		position: absolute;

		max-width: none;
		max-height: none;

		@if ($fill == 'width') {
			width: 100%;
			height: auto;
			min-height: none;
		} @else if ($fill == 'height') {
			width: auto;
			min-width: none;
			height: 100%;
		} @else {
			width: auto;
			min-width: 100%;
			height: auto;
			min-height: 100%;
		}


		@if( nth($pin, 1) == 'center' ) {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		} @else if( nth($pin, 1) == 'right' ) {
			right: 0;
			left: auto;
		} @else {
			right: auto;
			left: 0;
		}


		@if( nth($pin, 2) == 'center' ) {
			top: 50%;
			@if( nth($pin, 1) == 'center' ) {
				transform: translate(-50%, -50%);
			} @else {
				transform: translateY(-50%);
			}
		} @else {
			top: 0;
			bottom: 0;
		}
	}
}


// Use in conjunction with pseudo-cover. Applies a color to an image container.
// If mix-blend-mode is supported by the browser, a color layer will be applied
// above the image with the 'multiply' blend mode (blend mode can be changed
// using $mode argument). If the browser does not support mix-blend-mode
// (e.g. IE and Edge), the image will be made partially transparent
// on top of a colored layer. The effect is similar, but not identical.
// A modernizr function to check for mix-blend-mode is required for this
// mixin to function fully.
//
// Arguments:
//	$color[color hex value] default: primary theme color
//	$opacity[decimal value between 0 and 1] default: $opacity-overlay variable
//	$mode['multiply'|'overlay'|'screen'] default: 'multiply'

@mixin color-overlay($color:$color-primary, $opacity:$opacity-overlay, $mode:multiply) {
	&::after {
		@include pseudo-cover;
		background-color: rgba($color, $opacity);
	}

	.mix-blend-mode & {
		> img {
			opacity: $opacity * 0.5;
		}

		&::after {
			background-color: $color;
			mix-blend-mode: $mode;
		}
	}
}




