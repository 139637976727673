.teaser-collection {
    @include rr-slab;

    .collection__headline,
    .collection__items {
        @include rr-slab-wrapper;
    }
    
    .collection__headline {
        // asdf
    }

    .collection__items {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .collection__item {
            flex: 0 1 $collection-4up;
            margin-bottom: $margin-bottom;
            @include breakpoint(medium down) {
                flex: 0 1 $collection-2up;
            }
            @include breakpoint(small down) {
                flex: 0 1 100%;
                margin-bottom: $margin-bottom/1.5;
            }
            .teaser__headline {
                .headline-group {
                    @include fontstyle(h4);
                }
            }
        }
    }


    &.collection--three-column {
        .collection__items {
            .collection__item {
                flex: 0 1 $collection-3up;
                @include breakpoint(small down) {
                    flex: 0 1 100%;
                }
                .teaser__headline {
                    .headline-group {
                        @include fontstyle(h3);
                    }
                }
            }
        }
    }

    &.collection--two-column {
        .collection__items {
            .collection__item {
                flex: 0 1 $collection-2up;
                @include breakpoint(small down) {
                    flex: 0 1 100%;
                }
                .teaser__headline {
                    .headline-group {
                        @include fontstyle(h2);
                    }
                }
            }
        }
    }

}