ul.news-meta {
    @extend .dot-list;
    font-family: $font-primary;
    font-size: rem-calc(16);
    line-height: rem-calc(26);
    text-transform: none;
    li {
        margin-bottom: 0;
        font-size: inherit;
        line-height: inherit;
        a {
            @extend .link--tag;
        }
    }
}