.secondary-nav {
    margin-bottom: $margin-bottom-large;
    ul {
        @include strip-list;
        li {
            margin-bottom: rem-calc(4);
            a {
                font-family: $font-tertiary;
                font-size: rem-calc(22);
                font-weight: normal;
                padding: rem-calc(20 35);
                display: block;
                background-color: $color-tertiary;
                &:after {
                    background-color: $color-octonary;
                }
                &:hover,
                &:active,
                &:focus,
                &.active {
                    color: $color-text;
                }
                &.active {
                    background-color: $color-octonary;
                }
            }
        }
    }

    &.secondary-nav--alternate {
        ul {
            li {
                a {
                    background-color: $color-secondary;
                    &:after {
                        background-color: $color-tertiary;
                    }
                    &:hover,
                    &:active,
                    &:focus,
                    &.active {
                        color: #fff;
                    }
                    &.active {
                        background-color: $color-tertiary;
                    }
                }
            }
        }
    }
}