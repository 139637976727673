.teaser {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    .teaser__body {
        @include breakpoint(medium up) {
            order: 2;
        }
    }

    .teaser__media {
        @include breakpoint(medium up) {
            order: 1;
        }
    }

    &.teaser--background {
        .teaser__body {
            background-color: $color-shade-lightest;
            padding: rem-calc(40);
            @include breakpoint($fix-xsmall down) {
                padding: rem-calc(20);
            }
            &.teaser--background-grid {
                // background-image: 
            }
        }
    }

    &.teaser--highlight {
        @extend .teaser--background;
        .teaser__body {
            @include top-stroke($color: $color-highlight);
        }
    }
    &.teaser--profile {
        @extend .teaser--background;
        .teaser__body {
            @include top-stroke($color: $color-profile);
        }
    }


    &.teaser--media-row {
        flex-flow: row nowrap;
        justify-content: space-between;
        @include breakpoint($fix-small down) {
            flex-flow: row wrap;
        }
        .teaser__body,
        .teaser__media {
            flex: 0 1 48%;
            @include breakpoint($fix-small down) {
                flex: 0 1 100%;
            }
        }
        .teaser__body {
            order: 1;
        }
        .teaser__media {
            order: 2;
        }
        &.teaser--media-row-invert {
            .teaser__body {
                order: 2;
            }
            .teaser__media {
                order: 1;
            }
        }

        &.teaser--media-row-sixty-forty {
            .teaser__body {
                flex: 0 1 58%;
            }
            .teaser__media {
                flex: 0 1 38%;
            }
            @include breakpoint($fix-small down) {
                .teaser__body,
                .teaser__media {
                    flex: 0 1 100%;
                }
            }
        }
    }


    &.teaser--media-offset {
        @extend .teaser--background;
        align-items: center;
        @include breakpoint(medium only) {
            padding-top: rem-calc(40);
        }
        .teaser__body,
        .teaser__media {
            flex: 0 1 50%;
            position: relative;
            @include breakpoint($fix-small down) {
                flex: 0 1 100%;
            }
        }
        .teaser__body {
            z-index: 2;
            @include breakpoint(medium only) {
                flex: 0 1 60%;
            }
        }
        .teaser__media {
            z-index: 1;
            @include breakpoint(medium only) {
                flex: 0 1 40%;
                margin-top: rem-calc(-40);
                align-self: flex-start;
            }
            .media {
                margin-right: -10%;
                @include breakpoint($fix-small down) {
                    margin-right: 0;
                }
            }
        }

        &.teaser--media-offset-invert {
            .teaser__body {
                order: 1;
            }
            .teaser__media {
                order: 2;
                .media {
                    margin-left: -10%;
                    margin-right: 0;
                    @include breakpoint($fix-small down) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &.teaser--edge {
        @include rr-slab;
        .teaser__body {
            @include rr-slab-wrapper;
        }

        &.teaser--media-offset {
            @include breakpoint(medium only) {
                margin-top: rem-calc(40);
            }
            .teaser__body {
                & > * {
                    max-width: ($max-width/2) - rem-calc(40);
                }
            }
        }
    }
}