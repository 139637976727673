.social-media {
    a {
        @include link-strokeless;
        color: $color-tertiary;
        margin-right: rem-calc(5);
        &:last-child {
            margin-right: 0;
        }
        &:hover,
        &:active,
        &:focus {
            color: $color-secondary;
        }
        svg {
            width: rem-calc(26);
            height: rem-calc(26);
        }
    }
}