[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
    border: rgba($color-text, 0.25) 1px solid;
    box-shadow: none;
    margin: rem-calc(10 0 40 0);
    padding: rem-calc(16 14);
    line-height: 1em;
    height: auto;

    &:focus {
        border: $color-text 1px solid;
        box-shadow: 0 0 0;
    }

    &[disabled] {
        background: $color-shade-lighter;
        color: darken($color-shade, 5%);
    }
}

label > [type='checkbox'],
label > [type='radio'] {
    margin: 0;
    cursor: pointer;
}
ul.form-widget-list {
    @include strip-list;
    li {
        margin-bottom: rem-calc(16);
        label {
            display: inline-flex;
            align-items: baseline;
            cursor: pointer;
            [type='checkbox'],
            [type='radio'] {
                margin-right: rem-calc(10);
            }
        }
    }
}

fieldset {
    legend {
        margin-bottom: rem-calc(20);
    }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: rgba($color-text, 0.7);
}
input[disabled]::-webkit-input-placeholder,
textarea[disabled]::-webkit-input-placeholder {
    color: $color-field-disabled;
}

select {
    padding-right: rem-calc(30);
}

label {
    color: $color-text;
    font-size: rem-calc(16);
    line-height: rem-calc(26);
}

.is-error,
.is-valid,
fieldset[disabled] {
    border-width: 2px;
    border-style: solid;
}

.is-error {
    border-color: $color-accent-denary;
}

.is-valid {
    border-color: $color-accent-quinary;
}

fieldset {
    &.is-error,
    &.is-valid,
    &[disabled] {
        padding: rem-calc(20px);
        legend {
            padding: rem-calc(0 10px);
            margin-bottom: 0;
        }
    }
    &[disabled] {
        border-color: $color-shade;
        color: $color-field-disabled;
        cursor: not-allowed;
        label {
            color: $color-field-disabled;
            cursor: not-allowed;
        }
        label > [type='checkbox'],
        label > [type='radio'] {
            cursor: not-allowed;
        }
        ul.form-widget-list {
            li {
                label {
                    cursor: not-allowed;
                }
            }
        }
    }
}

label,
legend {
    abbr[title="Required"] {
        border: 0;
        color: $color-accent-denary;
        font-weight: bold;
        font-size: rem-calc(20);
        line-height: 0px;
    }
}