.fact {
	.fact__headline {
		margin-bottom: rem-calc(16);
		.superhead {
			// maybe nothing here
		}
		.head {
			// @include fontstyle('h2');
			font-weight: bold;
		}
		.subhead {
			margin-top: 0;
		}
	}
	.fact__body {

	}
}