

// ---------------------------------------------------------------
// resets/defaults
ul, ol {
	margin-bottom: $margin-bottom;
	margin-left: rem-calc(50);
	li {
		font-size: rem-calc(18);
		margin-bottom: rem-calc(16);
		ul, ol {
			margin-top: rem-calc(16);
			margin-left: rem-calc(50);
		}
	}
}

// @include breakpoint($fix-xsmall down) {
// 	ul, ol {
// 		margin-left: rem-calc(20);
// 		li {
// 			font-size: rem-calc(16);
// 			ul, ol {
// 				margin-left: rem-calc(40);
// 			}
// 		}
// 	}
// }


// ---------------------------------------------------------------
// strip list: remove all formatting, bullets, etc 
@mixin strip-list {
	margin: 0 0 0 0;
	list-style: none;
	padding: 0 0 0 0;
	&> li {
		text-indent: 0;
		margin-left: 0;
		margin-bottom: 0;
		&:before {
			display: none;
		}
	}
}

ul.strip-list {
	@include strip-list;
}

ol.strip-list {
	@include strip-list;
}

// ---------------------------------------------------------------
// keep formatting/margins/indents but lose bullets
@mixin no-bullets {
	list-style-type: none;
	margin-left: 0;
}

ul.no-bullets {
	@include no-bullets;
}


// ---------------------------------------------------------------
// loose and tight lists --> adjust spacing between list items
// depending on the content of the lists, as needed
@mixin loose-list {
	li {
		margin-bottom: rem-calc(30);
		ul, ol {
			margin-top: rem-calc(30);
		}
	}
}

ul, ol {
	&.loose-list {
		@include loose-list;
	}
}

@mixin tight-list {
	li {
		margin-bottom: rem-calc(10);
		ul, ol {
			margin-top: rem-calc(10);
		}
	}
}

ul, ol {
	&.tight-list {
		@include tight-list;
	}
}


// ---------------------------------------------------------------
// dot lists (uls)
// an inline list with items separated by dots
@mixin dot-list($dot-color: $color-shade) {
	@include no-bullets;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		&:after {
			display: inline-block;
			content: '';
			width: rem-calc(6);
			height: rem-calc(6);
			border-radius: 50%;
			background: $dot-color;
			margin: 0 rem-calc(16) 2% rem-calc(16);
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
		@include breakpoint($fix-xsmall down) {
			display: block;
			&:after {
				display: none;
			}
		}
	}
}

ul.dot-list {
	@include dot-list;
}


// ---------------------------------------------------------------
// definition lists
dl {
	dt,
	dd {
		font-size: rem-calc(18);
		// @include breakpoint($fix-xsmall down) {
		// 	font-size: rem-calc(16);
		// }
	}
	dt {
		// 
	}
	dd {
		margin-bottom: rem-calc(20);
	}
}