a {
    text-decoration: none;
    color: $color-link;
    &:link,
    &:visited {
        color: $color-link;
    }
    &:hover,
    &:active,
    &:focus {
        color: $color-link; // confirm this later pls
    }
    &:focus {
		outline-color: $color-outline;
    }

    // fancy underlines
    background-image: linear-gradient(120deg, $color-octonary 0%, $color-septenary 100%);
    background-repeat: no-repeat;
    // background-size: 100% 0.2em;
    background-size: 100% 2px;
    background-position: 0 98%;
    transition: background-size 0.25s ease-in;
    &:hover {
        background-size: 100% 98%;
    }

    &.link--tag {
        font-weight: bold;
    }

    &.link--fancy {
        background-image: none;
        font-family: $font-tertiary;
        font-size: rem-calc(24);
        line-height: rem-calc(28);
        display: inline-flex;
        transition: color $transition-time $transition-easing;
        padding-top: rem-calc(7);
        &:before {
            display: inline-block;
            content: '';
            width: rem-calc(40);
            height: rem-calc(40);
            border-radius: 50%;
            border: $color-shade-lighter 2px solid;
            flex: 0 0 auto;
            margin-right: rem-calc(20);
            margin-top: rem-calc(-7);
            transition: border-color $transition-time $transition-easing;
            background: image-url('fancy-link-angle.svg') no-repeat center center;
            background-size: 7px 12px;

        }
        &:hover,
        &:active,
        &:focus {
            color: $color-tertiary;
            &:before {
                border-color: $color-tertiary;
            }
        }
        &.link--fancy-filled {
            &:before {
                border: transparent;
                background-color: $color-link;
                transition: background-color $transition-time $transition-easing;
                background-image: image-url('fancy-link-angle-white.svg');
            }
            &:hover,
            &:active,
            &:focus {
                &:before {
                    background-color: $color-tertiary;
                }
            }
        }
    }
}

@mixin link-strokeless {
    background-image: none;
    transition: color $transition-time $transition-easing;
    &:hover {
        color: $color-link-hover;
    }
}

a.link--strokeless {
    @include link-strokeless;
}