table {
    border-collapse: collapse;
    border: 0;
    margin-bottom: $margin-bottom;
    thead,
    tfoot,
    tbody {
        th,
        td {
            padding: rem-calc(16 20);
        }
    }
    thead,
    tfoot {
        tr {
            th {
                text-align: left;
            }
        }
    }
    thead {
        tr{
            th {
                background: $color-tertiary;
                border: rgba(#000, 0.2) 1px solid;
                color: #fff;
            }
        }
    }
    tfoot {
        tr {
            th {
                background: $color-shade-light;
                border: rgba(#000, 0.1) 1px solid;
                color: $color-text;
            }
        }
    }
    tbody {
        tr,
        tr:nth-child(even) {
            background: #fff;
            th {
                text-align: left;
            }
            th,
            td {
                border: $color-shade-lighter 1px solid;
            }
        }
    }
    caption {
        margin-bottom: $margin-bottom/2;
    }
}


table.table--zebra-stripe {
    tbody {
        tr:nth-child(even) {
            background: $color-shade-lightest;
        }
    }
}

.table-responsive-wrap {
    @include breakpoint(medium down) {
        overflow-x: auto;
    }
}