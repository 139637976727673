
.button,
button {
	@include fontstyle('button');

	border-radius: 0;
	border-width: 0;
	display: inline-block;
	padding: rem-calc(13 27);
	background-color: $color-secondary;
	color: $white;
	cursor: pointer;
	transition: background $transition-time $transition-easing;

	// everything below this = styles for the triangle
	position: relative;
	overflow: hidden;
	span {
		position: relative;
		z-index: 2;
	}
	&:after {
		position: absolute;
		display: inline-block;
		content: '';
		$initial-size: rem-calc(30);
		z-index: 1;
		width: $initial-size;
		height: $initial-size;
		transform: rotate(-45deg);
		top: -$initial-size/2;
		left: -$initial-size/2;
		background: $color-tertiary;
		transition: transform $transition-time $transition-easing, background $transition-time $transition-easing;
	}
	&:hover {
		&:after {
			transform: rotate(-45deg) scale(20);
			// background: $color-tertiary;
		}
	}
	// end of triangle styles :)

	&.button--block {
		display: block;
		text-align: center;
	}
}

// need hover states for the elements that don't
// support the triangle/psudoelement effect
input[type=button],
input[type=submit] {
	transition: background $transition-time $transition-easing;
	&:hover,
	&:active {
		background: $color-tertiary;
	}
}

// settings for when the button is a link/anchor element
.button {
	@include link-strokeless;
	&:link,
	&:visited {
		color: $white;
		text-decoration: none;
	}

	&:hover,
	&:active {
		color: $white;
		text-decoration: none;
	}
}

// always use the pointing cursor on buttons
button {
	cursor: pointer;
}

// make reset buttons look different/less important
// than submit buttons
.button[type=reset],
input[type=reset] {
	transition: background $transition-time $transition-easing;
	background-color: $color-shade-darker;
	&:hover,
	&:active,
	&:focus {
		background-color: darken($color-shade-darker, 20%);
	}
}

// option to strip the styling from buttons
// useful for when a toggler needs to be a button for accessibility,
// but visually should look like a link
@mixin strip-button {
	display: inline;
	padding: 0;
	background: transparent;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	transition: color $transition-time $transition-easing;
	&:after {
		display: none;
	}
}

.button,
button {
	&.button--strip {
		@include strip-button;
	}
}

// make a button look like a text link (strokeless)
// useful for same reason as strip-button
@mixin link-style-button {
	@include strip-button;
	color: $color-link;
	transition: color $transition-time $transition-easing;
	&:hover,
	&:active,
	&:focus {
		color: $color-link-hover;
	}
}

.button,
button {
	&.button--link-style {
		@include link-style-button;
	}
}


// option to remove the triangle
@mixin no-triangle-button {
	transition: background $transition-time $transition-easing;
	&:after {
		display: none;
	}
	&:hover,
	&:active {
		background: $color-tertiary;
	}
}

.button,
button {
	&.button--no-triangle {
		@include no-triangle-button;
	}
}