$heading-line-height: 1.2;

@mixin fontstyle($stylename: "default") {
	@if $stylename == "h1" {
		font: normal normal rem-calc(60) $font-secondary;
		line-height: $heading-line-height;
		text-transform: none;
		@include breakpoint($fix-small down) {
			font-size: rem-calc(50);
		}
		@include breakpoint($fix-xsmall down) {
			font-size: rem-calc(38);
		}
	}
	@elseif $stylename == "h2" {
		font: normal normal rem-calc(46) $font-secondary;
		line-height: $heading-line-height;
		text-transform: none;
		@include breakpoint($fix-small down) {
			font-size: rem-calc(40);
		}
		@include breakpoint($fix-xsmall down) {
			font-size: rem-calc(34);
		}
	}
	@elseif $stylename == "h3" {
		font: normal normal rem-calc(34) $font-secondary;
		line-height: $heading-line-height;
		text-transform: none;
		@include breakpoint($fix-xsmall down) {
			font-size: rem-calc(30);
		}
	}
	@elseif $stylename == "h4" {
		font: normal normal rem-calc(30) $font-secondary;
		line-height: $heading-line-height;
		text-transform: none;
		@include breakpoint($fix-xsmall down) {
			font-size: rem-calc(26);
		}
	}
	@elseif $stylename == "h5" {
		font: normal normal rem-calc(26) $font-secondary;
		line-height: $heading-line-height;
		text-transform: none;
		@include breakpoint($fix-xsmall down) {
			font-size: rem-calc(22);
		}
	}
	@elseif $stylename == "h6" {
		font: normal normal rem-calc(20) $font-secondary;
		line-height: $heading-line-height;
		text-transform: none;
	}
	@elseif $stylename == "button" {
		font: normal bold rem-calc(16) $font-primary;
		line-height: rem-calc(26);
		text-transform: none;
	}
	@elseif $stylename == "big-barlow" {
		font-family: $font-tertiary;
		font-size: rem-calc(28);
		line-height: rem-calc(38);
		@include breakpoint($fix-xsmall down) {
			font-size: rem-calc(24);
			line-height: rem-calc(34);
		}
	}
	@else {
		font: normal normal $base-font-size $font-primary;
		text-transform: none;
		line-height: rem-calc(26);
	}
}

