.global-utility-nav {
    ul {
        @include strip-list;
        display: flex;
        li {
            margin-right: rem-calc(30);
            font-size: rem-calc(14);
            font-weight: bold;
			&:last-child {
				margin-right: 0;
            }
            a {
                // @include link-strokeless;
            }
            button {
                @include strip-button;
                color: $color-primary;
                &.toggleSearch {
                    display: flex;
                    align-items: center;
                    svg {
                        margin-left: rem-calc(10);
                        width: 1.3em;
                        height: 1.3em;
                        &.close {
                            display: none;
                        }
                    }
                    // svg.close {
					// 	display: none;
					// }
					&.toggleSearch--active {
						svg.close {
							display: inline-block;
						}
						svg.search {
							display: none;
						}
					}
                }
            }
        }
    }
    .search-bar {
        display: none;
    }
}


// collabsible list view (tablet-ish and down)
@include breakpoint(medium down) {
    .global-utility-nav {
        background: $color-shade-lightest;
        padding: rem-calc(30);
        ul {
            flex-flow: row wrap;
            li {
                flex: 0 1 100%;
                margin-bottom: rem-calc(30);
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    // possibly nothing
                }
                button {
                    &.toggleSearch {
                        display: none;
                    }
                }
            }
        }
        .search-bar {
            display: block;
        }
    }
}