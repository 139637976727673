.main-footer {
	// @include rr_slab;

	background: $color-primary;
	@include ondark;
	font-size: rem-calc(14);
	line-height: rem-calc(22);

	ul {
		@include strip-list;
		li {
			font-size: inherit;
			line-height: inherit;
		}
	}
	.button {
		font-size: inherit;
	}

	.main-footer__ribbon {
		background: rgba(#000, 0.2);
	}

	.main-footer__primary,
	.main-footer__ribbon {
		@include rr_slab;
		.main-footer__primary-wrapper,
		.main-footer__ribbon-wrapper {
			@include rr_slab_wrapper;
			display: flex;
			flex-flow: row wrap;
		}
	}

	.main-footer__primary {
		padding-top: rem-calc(40);
		padding-bottom: rem-calc(40);
		overflow: hidden;
		.main-footer__primary-wrapper {
			justify-content: space-between;
			@include breakpoint(medium down) {
				flex-flow: row wrap;
			}

			.main-footer__logo {
				flex: 0 1 35%;
				position: relative;
				&:after {
					display: inline-block;
					content: '';
					position: absolute;
					background: image-url('triangle-single-down.svg') no-repeat top left;
					width: rem-calc(785);
					height: rem-calc(396);
					top: rem-calc(-40);
					right: -30%;
					mix-blend-mode: hard-light;
					z-index: 0;
					@include breakpoint(large down) {
						right: auto;
						left: rem-calc(-160);
					}
				}
				@include breakpoint(large down) {
					flex: 0 1 22%;
				}
				@include breakpoint(medium down) {
					flex: 0 1 30%;
				}
				@include breakpoint($fix-small down) {
					flex: 0 1 100%;
					margin-bottom: rem-calc(30);
				}
				.logo {
					display: inline-block;
					max-width: rem-calc(250);
					position: relative;
					z-index: 2;
				}
			}

			.link-collection,
			.main-footer__support-contact {
				flex: 0 1 19%;
				position: relative;
				z-index: 2;
				@include breakpoint(large down) {
					flex: 0 1 22%;
				}
			}

			.link-collection {
				border-top: rgba(#fff, 0.25) 1px solid;
				@include breakpoint(medium down) {
					flex: 0 1 30%;
				}
				@include breakpoint($fix-small down) {
					flex: 0 1 47%;
				}
				@include breakpoint($fix-xsmall down) {
					flex: 0 1 100%;
				}
				h1, h2, h3, h4, h5, h6 {
					// @include fontstyle(h4);
					color: $color-quaternary;
					font-size: rem-calc(22);
					line-height: rem-calc(32);
					margin: rem-calc(10) 0;
				}
				ul {
					li {
						margin-bottom: rem-calc(10);
						&:last-child {
							margin-bottom: 0;
						}
						@include breakpoint($fix-small down) {
							margin-bottom: rem-calc(20);
						}
					}
				}
				.vcard {
					margin-bottom: rem-calc(20);
					span.title {
						font-weight: bold;
					}
				}
			}

			.main-footer__support-contact {
				@include breakpoint(medium down) {
					flex: 0 1 100%;
					display: flex;
					align-items: center;
				}
				@include breakpoint($fix-xsmall down) {
					display: block;
				}
				.button {
					margin-bottom: rem-calc(20);
					@include breakpoint(medium down) {
						margin-bottom: 0;
						margin-right: rem-calc(20);
					}
					@include breakpoint($fix-xsmall down) {
						// margin-bottom: 0;
						margin-bottom: rem-calc(20);
					}
				}
			}
		}
	}

	.main-footer__ribbon-wrapper {
		justify-content: flex-start;
		@include breakpoint($fix-small down) {
			padding-top: rem-calc(20);
		}

		.link-collection {
			@include breakpoint($fix-small down) {
				flex: 0 1 100%;
			}
			ul {
				li {
					display: inline;
					margin-left: rem-calc(30);
					@include breakpoint($fix-small down) {
						display: block;
						margin-left: 0;
						margin-bottom: rem-calc(20);
						&:first-child {
							margin-top: rem-calc(20);
						}
					}
				}
			}
		}
	}

}
