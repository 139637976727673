.section-header {
    margin-bottom: $margin-bottom;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @include breakpoint($fix-xsmall down) {
        margin-bottom: $margin-bottom/2;
        flex-flow: column nowrap;
    }

    .headline-group {
        margin-right: rem-calc(20);
        @include breakpoint($fix-xsmall down) {
            margin-right: 0;
        }
    }

    .section-header__button {
        // align-self: flex-end;
        button,
        .button {
            margin-bottom: 0;
        }
    }
}