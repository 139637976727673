/* Colors
/* remember to turn off any unused swatches in atoms>global>colors.twig
/* _______________________________________________________________ */

$color-undefined: #000;
$white: #fff;
$black: #000;


// Brand Color Palette: primaries
$color-primary: #012169;
$color-secondary: #0033A0;
$color-tertiary: #007DBA;
$color-quaternary: #F2A900;
$color-quinary: #B58500;
$color-senary: #8D702A;
$color-septenary: #00AEEF;
$color-octonary: #41B6E6;
$color-nonary: $color-undefined;
$color-denary: $color-undefined;


// Brand Color Palette: accents
$color-accent-primary: #9EE1E7;
$color-accent-secondary: #55A0A6;
$color-accent-tertiary: #487F84;
$color-accent-quaternary: #99E69D;
$color-accent-quinary: #348338;
$color-accent-senary: #006C5B;
$color-accent-septenary: #006C5B;
$color-accent-octonary: #C35413;
$color-accent-nonary: #D3710C;
$color-accent-denary: #DA291C;
$color-accent-eleven: #C6007E;
$color-accent-twelve: #6558B1;
$color-accent-thirteen: #6D2077;


$color-shade-base: #B1B3B3;

// Grays / Shades / Support Colors
$color-shade-lightest: #F3F3F2;
$color-shade-lighter: #D9D9D6;
$color-shade-light: #D0D0CE;
$color-shade: $color-shade-base;
$color-shade-dark: darken($color-shade-base, 10%);
$color-shade-darker: darken($color-shade-base, 20%);
$color-shade-darkest: #101820;

// Global Color Settings
// ____________________________________

// text & links
$color-text: $color-shade-darkest;
$color-link: $color-secondary;
$color-link-hover: $color-tertiary;
$color-main-nav-links: $color-primary;

// messaging
$color-success: $color-nonary;
$color-warning: $color-senary;
$color-alert: $color-quinary;
$color-field-disabled: darken($color-shade, 5%);

// misc or sort later
$color-bg: $color-shade-lightest;
$color-border: $color-shade;
$color-outline: $color-quaternary;
$color-highlight: $color-accent-eleven;
$color-profile: $color-accent-octonary;