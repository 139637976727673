@for $i from 1 through 6 {
	h#{$i},
	.visually-h#{$i} {
		@include fontstyle("h#{$i}");
		margin: rem-calc(0 0 16 0);

		a {
			background-position: 0 78%;
			&:hover {
				background-size: 100% 88%;
			}
		}
	}
}
