$icon-size: rem-calc(26);
$bar-size: rem-calc(3);

.global-mobile-toggle {
    span.hamburger-icon {
        margin: 0 auto rem-calc(10) auto;
        display: block;
        position: relative;
        width: $icon-size;
        height: $icon-size;
        span {
            display: block;
            position: absolute;
            height: $bar-size;
            width: 100%;
            background: #fff;
            border-radius: rem-calc(9);
            opacity: 1;
            left: 0;
            transition: .25s ease-in-out;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2),
            &:nth-child(3) {
                top: ($icon-size/2) - ($bar-size/2);
            }
            &:nth-child(4) {
                bottom: 0;
            }
        }
    }
    button {
        @include strip-button;
        color: $color-primary;
        text-transform: uppercase;
        font-weight: bold;
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        padding: rem-calc(10);
        span.hamburger-icon {
            span {
                background-color: $color-primary;
            }
        }
        &:hover,
        &:focus {
            color: $color-primary;
            span.hamburger-icon {
                span {
                    background-color: $color-tertiary;
                }
            }
        }
    }
}

body.mobile-nav-open {
    .global-mobile-toggle {
        span.hamburger-icon {
            span {
                &:nth-child(1),
                &:nth-child(4) {
                    top: ($icon-size/2) - ($bar-size/2);
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}